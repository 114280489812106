//TODO: Stepper wizard name needs to be changed to add generic properties
export const stepperWizard = {
  title: 'CREATE_JOBSITE',
  btnConfig: {
    prevBtnLabel: 'Previous',
    nextBtnLabel: 'Next',
    finishBtnLabel: 'Finish',
    updateBtnLabel: 'Update',
    skipBtnLabel: 'Skip'
  },
  steps: [
    {
      label: 'Set Location & Name',
      isOptional: false,
      isCompleted: false,
      visibilityCriteria: []
    },
    {
      label: 'Select Assets',
      isOptional: false,
      isCompleted: false,
      visibilityCriteria: []
    },
    {
      label: 'Create Zones',
      isOptional: true,
      isCompleted: false,
      visibilityCriteria: []
    },
    {
      label: 'Assign Schedule',
      isOptional: false,
      isCompleted: false,
      visibilityCriteria: []
    },
    {
      label: 'Review & Confirm',
      isOptional: false,
      isCompleted: false,
      visibilityCriteria: []
    }
  ],
  config: {
    addAppName: true,
    createGroupId: true
  }
};

export const API_MOCK_DATA = {
  groups: [
    { id: 1283, name: '设备得到的数据 ', description: '设备得到的数据 ', parentID: 3, ucid: '2969514837', group: [] },
    { id: 1278, name: 'ttco1 ', description: 'ttco1 ', parentID: 3, ucid: '2969481315', group: [] },
    { id: 1167, name: 'TD00', description: 'TD00', parentID: 3, ucid: 'TD00', group: [] },
    { id: 1055, name: 'MANI MANI', description: 'MANI MANI', parentID: 3, ucid: '2969473025', group: [] },
    { id: 1289, name: 'MAB ', description: 'MAB ', parentID: 3, ucid: '2969481313', group: [] },
    { id: 1207, name: 'Kinsley Constr ', description: 'Kinsley Constr ', parentID: 3, ucid: '2969473504', group: [] },
    { id: 1083, name: 'KIEWIT ', description: 'KIEWIT ', parentID: 3, ucid: '2969472760', group: [] },
    { id: 1263, name: 'Enmaco Motorer ', description: 'Enmaco Motorer ', parentID: 3, ucid: '2969473140', group: [] },
    { id: 1279, name: 'ED Test Test', description: 'ED Test Test', parentID: 3, ucid: '2969473160', group: [] },
    { id: 1556, name: 'E2E Customer 1 ', description: 'E2E Customer 1 ', parentID: 3, ucid: '2969518742', group: [] },
    {
      id: 1340,
      name: 'Coal Mine Ranch Joint Venture ',
      description: 'Coal Mine Ranch Joint Venture ',
      parentID: 3,
      ucid: '2969480800',
      group: []
    },
    { id: 1171, name: 'Caterpillar Inc. ', description: 'Caterpillar Inc. ', parentID: 3, ucid: '2969473117', group: [] },
    {
      id: 1282,
      name: 'BHP Billiton Petroleum Ltd ',
      description: 'BHP Billiton Petroleum Ltd ',
      parentID: 3,
      ucid: '2969476554',
      group: []
    },
    { id: 35362, name: '2969519191', description: '2969519191', parentID: 3, ucid: '2969519191', group: [] },
    { id: 1277, name: '2969515985', description: '2969515985', parentID: 3, ucid: '2969515985', group: [] }
  ],
  totalCount: 25
};
