import { Preference } from '@Terra/shared/widgets/interface';
import * as config from '../appStore.config';
import { UsersURL } from '../appStore.config';
import { ITemplateType } from '../models/ITemplateType.model';
import { AvailableLanguage, Group } from '../models/app.model';
import { EnvironmentActionTypes, EnvironmentActions } from './environment.actions';
/**
 * Interface for the 'Environment' data used in
 *  - EnvironmentState, and
 *  - environmentReducer
 */

/**
 * Interface to the part of the Store containing EnvironmentState
 * and other information related to EnvironmentData.
 */
export interface EnvironmentData {
  env: string;
  nodeBaseURL: string;
  auth_ms_baseURL: string;
  site_url?: SiteURL;
  assets_URL: string;
  assigned_assets_URL: string;
  assigned_jobsite_summary_URL: string;
  CCDS_ASSET_HEADER: string;
  SUBSCRIPTION_KEY: string;
  isAPIGee: boolean;
  groupId_URL: string;
  users_URL?: UsersURL;
  nodeURL?: any;
  preference_Endpoints?: any;
  segment_url?: SegmentURL;
  segment_URL: string;
  shift_url?: ShiftURL;
  post_shift_url?: ShiftURL;
  report_template_id: ITemplateType;
  PI_BaseURL: string;
  PM_Utilization_BaseURL: string;
  PM_Productivity_BaseURL: string;
  vocBaseURL: string;
  vocAPPID: string;
  defaultPreferences: Preference;
  rootGroupInfo: Group;
  CCDS_BaseURL?: string;
  wafEnabled: boolean;
  heliosBaseURL: string;
  applicationID: number;
  downloadDataBaseURL: string;
  downloadUsersBaseURL: string;
  helpUrl: string;
  mccURL: string;
  availableLanguage?: AvailableLanguage;
  productFamilyImgBaseUrl: string;
}

interface SiteURL {
  GET: string;
  POST: string;
  PUT: string;
  DELETE: string;
  PATCH: string;
  POST_SITES: string;
}

interface ShiftURL {
  GET?: string;
  POST?: string;
}

interface SegmentURL {
  GET: string;
  POST: string;
  PUT: string;
  DELETE: string;
}

export interface EnvironmentState {
  readonly environment: EnvironmentData;
}

export const initialState: EnvironmentData = {
  env: '',
  nodeBaseURL: 'https://int-vlproductivity.cat.com',
  auth_ms_baseURL: '',
  site_url: null,
  assets_URL: '',
  assigned_assets_URL: '',
  assigned_jobsite_summary_URL: '',
  CCDS_ASSET_HEADER: '',
  SUBSCRIPTION_KEY: '',
  groupId_URL: '',
  isAPIGee: false,
  users_URL: null,
  nodeURL: null,
  preference_Endpoints: null,
  segment_URL: '',
  shift_url: null,
  post_shift_url: null,
  report_template_id: { PDF: -1, CSV: -1, Multi_CSV: -1, XLSX: -1, XLS: -1 },
  PI_BaseURL: null,
  PM_Utilization_BaseURL: null,
  PM_Productivity_BaseURL: null,
  vocBaseURL: '',
  vocAPPID: '',
  defaultPreferences: null,
  rootGroupInfo: null,
  CCDS_BaseURL: null,
  wafEnabled: true,
  heliosBaseURL: '',
  applicationID: 3,
  downloadDataBaseURL: null,
  downloadUsersBaseURL: null,
  helpUrl: '',
  mccURL: '',
  availableLanguage: null,
  productFamilyImgBaseUrl: ''
};

export function environmentReducer(state = initialState, action: EnvironmentActions): EnvironmentData {
  switch (action.type) {
    case EnvironmentActionTypes.EnvironmentLoaded: {
      return { ...state, ...action.payload };
    }

    case EnvironmentActionTypes.IdentifyEnvironment: {
      const ENV_LOCAL_IDENTIFIER = config.ENV_CONFIG_BETA[action.env];
      const MODIFIED_ENV_STATE: EnvironmentData = {
        env: ENV_LOCAL_IDENTIFIER,
        nodeBaseURL: action.baseURL,
        auth_ms_baseURL: config.AUTH_MS_BASEURL[ENV_LOCAL_IDENTIFIER],
        site_url: config.siteUrl.sites,
        assets_URL: config.ASSET_ENDPOINT,
        assigned_assets_URL: config.ASSIGNED_ASSET_ENDPOINT,
        assigned_jobsite_summary_URL: config.ASSIGNED_JOBSITE_SUMMARY_ENDPOINT,
        groupId_URL: config.CREATE_GROUPID,
        CCDS_ASSET_HEADER: config.ASSET_HEADER,
        SUBSCRIPTION_KEY: config.SUBSCRIPTION_KEY,
        isAPIGee: false,
        users_URL: config.usersURL,
        nodeURL: config.nodeURL,
        preference_Endpoints: config.preferenceEndPoint,
        segment_URL: config.GET_SEGMENTS,
        shift_url: config.shiftUrl.shifts,
        post_shift_url: config.shiftUrl.postShift,
        report_template_id: config.REPORTS_TEMPLATE_ID[ENV_LOCAL_IDENTIFIER],
        PI_BaseURL: config.PI_BASE_URL[ENV_LOCAL_IDENTIFIER],
        PM_Utilization_BaseURL: config.PM_UTILIZATION_BASE_URL[ENV_LOCAL_IDENTIFIER],
        PM_Productivity_BaseURL: config.PM_PRODUCTIVITY_BASE_URL[ENV_LOCAL_IDENTIFIER],
        vocBaseURL: config.VOC_BASE_URL[ENV_LOCAL_IDENTIFIER],
        vocAPPID: config.VOC_APP_ID[ENV_LOCAL_IDENTIFIER],
        defaultPreferences: config.defaultPreferences,
        rootGroupInfo: config.USER_MANAGEMENT_CONFIG.rootGroupInfo,
        CCDS_BaseURL: config.HELIOS_BASE_URL[ENV_LOCAL_IDENTIFIER],
        wafEnabled: true,
        heliosBaseURL: config.HELIOS_PREFERENCES_ENDPOINTS[ENV_LOCAL_IDENTIFIER],
        applicationID: 3,
        downloadDataBaseURL: config.DOWNLOAD_DATA_BASE_URL[ENV_LOCAL_IDENTIFIER],
        downloadUsersBaseURL: config.PM_USER_SERVICE_BASE_URL[ENV_LOCAL_IDENTIFIER],
        helpUrl: config.HELP_URL[ENV_LOCAL_IDENTIFIER],
        mccURL: config.MCC_BASE_URL[ENV_LOCAL_IDENTIFIER],
        availableLanguage: {
          defaultLanguage: config.defaultLanguage,
          preferredLanguage: config.prefferedLanguages
        },
        productFamilyImgBaseUrl: config.PRODUCT_FAMILY_LOGO_URL[ENV_LOCAL_IDENTIFIER]
      };

      return {
        ...state,
        ...MODIFIED_ENV_STATE
      };
    }

    default:
      return state;
  }
}
