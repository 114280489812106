import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { EnvironmentActionTypes, EnvironmentLoaded, LoadEnvironment } from './environment.actions';
import { EnvironmentState } from './environment.reducer';

@Injectable()
export class EnvironmentEffects {
  constructor(private readonly actions$: Actions) {}

  loadEnvironment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActionTypes.LoadEnvironment),
      fetch({
        run: (action: LoadEnvironment, state: EnvironmentState) => new EnvironmentLoaded(state),

        onError: (action: LoadEnvironment, error) => {
          // eslint-disable-next-line no-console
          console.error('Error', error);
        }
      })
    )
  );
}
