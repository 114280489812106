import { HeliosCustomPreference } from '@Terra/shared/shared-lib';
import { CONSENT_STATUS } from '@Terra/shared/widgets/config';
import { HeliosUserPreferenceResponse } from '@Terra/shared/widgets/interface';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppService } from './app.service';
import { ConsentInfo } from './models/app.model';

@Injectable({
  providedIn: 'root'
})
export class LegalUtilService {
  consentStatus$ = new BehaviorSubject<ConsentInfo>(null);
  showLegalFooter$ = new BehaviorSubject<string>(null);
  rawGlobalPreference: HeliosUserPreferenceResponse;

  constructor(private appService: AppService) {}

  loadConsentStatus(heliosCustomPref: HeliosCustomPreference, consentResponse: ConsentInfo): void {
    const consentStatus =
      consentResponse.isFailed && heliosCustomPref.consentStatus === CONSENT_STATUS.AGREE ? CONSENT_STATUS.AGREE : consentResponse.status;
    this.updateConsentStatus({ status: consentStatus });
    if (
      consentResponse.status === CONSENT_STATUS.AGREE &&
      (!heliosCustomPref.consentStatus || consentResponse.status !== heliosCustomPref.consentStatus)
    ) {
      this.patchConsentToCustomPref({ consentStatus: CONSENT_STATUS.AGREE });
    }
  }

  updateConsentStatus(consentInfo: ConsentInfo): void {
    this.consentStatus$.next(consentInfo);
  }

  patchConsentToCustomPref(payload: HeliosCustomPreference): void {
    this.appService.updateHeliosCustomPreference(payload).subscribe(data => data);
  }

  get langFromGlobalPref(): string {
    const globalLang = this.rawGlobalPreference?.userProvided?.language;
    return this.rawGlobalPreference?.isFailed ? null : globalLang;
  }
}
