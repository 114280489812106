import { CP_DEFAULT_USER_GLOBAL_PREFERENCE_VALUE, CP_USER_PREFERENCE_VALUE, HeliosCustomPreference } from '@Terra/shared/shared-lib';
import {
  HeliosUserPreference,
  HeliosUserPreferenceResponse,
  Preference,
  ProductFamilyConfig,
  RequestType
} from '@Terra/shared/widgets/interface';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import {
  APP_API_URLS,
  APP_NOTIFICATION_HANDLER_URL,
  CONSTANTS,
  CUST_SUMMARY_PAYLOAD,
  HELIOS_CUSTOM_PREF_ENDPOINT,
  IMPLICIT_PARTIES_PAYLOAD,
  INDUSTRYTYPE_PAYLOAD,
  NODE_SERVER,
  xOrganizationId
} from './appStore.config';

import { BASE_URL } from '@Terra/shared/widgets/config';
import {
  BotSecretKey,
  ConsentStatusResponse,
  CustSummaryResponse,
  NotificationResponse,
  PartiesDetails,
  PartiesDetailsPayload,
  PendingUpgradeRequests,
  TimeZoneList
} from './models/app.model';
@Injectable()
export class AppService {
  env: string;
  private envObj: any;
  authMsBaseURL: string;
  vocAPPID: string;
  constructor(private readonly http: HttpClient, private readonly store: Store<any>) {
    this.constructEnv();
  }

  constructEnv() {
    this.store
      .select((state: any) => state.environment)
      .subscribe(response => {
        if (response) {
          this.envObj = response;
          this.env = response.env;
          this.authMsBaseURL = response.auth_ms_baseURL;
          this.vocAPPID = response.vocAPPID;
        }
      });
  }

  fetchRemeberMe(): Observable<any> {
    const finalUrl = this.envObj.nodeBaseURL + NODE_SERVER.PREFERENCE_ROUTE;
    const params = new HttpParams().append(NODE_SERVER.HANDLER_URL, APP_API_URLS.getRemeberMe).append('canShowSpinner', 'true');
    return this.http.get(finalUrl, { params }).pipe(retry(2));
  }

  getFeaturesList(ucid: string, canRetry: boolean): Observable<any> {
    const url = `${APP_API_URLS.getGroups}/${ucid}/${CONSTANTS.Feature}`;
    const finalUrl = this.envObj.nodeBaseURL + NODE_SERVER.FEATURE_ROUTE;
    const params = new HttpParams().append(NODE_SERVER.HANDLER_URL, url).append('canShowSpinner', 'true');
    return this.http.get(finalUrl, { params }).pipe(canRetry ? retry(2) : retry(0));
  }

  getIndustryTypes(payload = INDUSTRYTYPE_PAYLOAD): Observable<any> {
    const url = `${APP_API_URLS.industryTpe}${payload.query}`;
    const params = new HttpParams().append(NODE_SERVER.HANDLER_URL, url).append('canShowSpinner', 'true');
    return this.http.get(NODE_SERVER.GET_INDUSTRY_TYPES, { params }).pipe(retry(2));
  }

  getConsentStatus(): Observable<ConsentStatusResponse> {
    const queryParams = `appId=${this.vocAPPID}`;
    const payload: RequestType = {
      method: 'get',
      headers: {
        Authorization: '',
        Accept: 'application/json'
      },
      forEach: []
    };
    const body = {
      handlerURL: `${APP_API_URLS.vocWrapper}${APP_API_URLS.getConsentStatus}?${queryParams}`,
      payload,
      skipDefault: true
    };
    const params = new HttpParams().append('canShowSpinner', 'true');
    return this.http.post<ConsentStatusResponse>(NODE_SERVER.GET_CONSENT_STATUS, body, { params });
  }

  getProductFamilyConfig(): Observable<ProductFamilyConfig> {
    const params = new HttpParams().append(NODE_SERVER.HANDLER_URL, `${APP_API_URLS.productFamily}`).append('canShowSpinner', 'true');
    return this.http.get<ProductFamilyConfig>(NODE_SERVER.GET_PRODUCT_FAMILY, { params }).pipe(retry(2));
  }

  getTimeZoneList(): Observable<TimeZoneList[]> {
    const params = new HttpParams().append(NODE_SERVER.HANDLER_URL, `${APP_API_URLS.getTimeZoneList}`).append('canShowSpinner', 'true');
    return this.http.get<TimeZoneList[]>(NODE_SERVER.GET_TIMEZONES_LIST, { params }).pipe(retry(2));
  }

  getPartiesAndPermissions(): Observable<HttpResponse<PartiesDetails>> {
    const params = new HttpParams().append('canShowSpinner', 'true');
    const finalUrl = BASE_URL.HELIOS_BASE_URL + APP_API_URLS.getParties;
    return this.http.get<PartiesDetails>(finalUrl, { params, observe: 'response' });
  }

  getCustSummary(payload: PartiesDetailsPayload): Observable<HttpResponse<CustSummaryResponse>> {
    let queryParams = `${CUST_SUMMARY_PAYLOAD.DEFAULT_QUERY}&limit=${payload.limit}`;
    queryParams = payload.cursor ? `${queryParams}&${CUST_SUMMARY_PAYLOAD.CURSOR}=${payload.cursor}` : queryParams;
    queryParams = payload.searchValue
      ? `${queryParams}&${CUST_SUMMARY_PAYLOAD.SEARCH_VALUE}=${encodeURIComponent(payload.searchValue)}`
      : queryParams;
    const URLs = `${APP_API_URLS.getCustSummary}${queryParams}`.split('?');
    const handlerURL = URLs[0];
    let params = new HttpParams().append('canShowSpinner', true);
    if (payload.cursor || payload.searchValue) {
      params = new HttpParams();
    }
    const finalUrl = BASE_URL.HELIOS_CCDS_BASE_URL + handlerURL + '?' + queryParams;
    return this.http.get<CustSummaryResponse>(finalUrl, { params, observe: 'response' });
  }

  getImplicitParties(payload: PartiesDetailsPayload): Observable<CustSummaryResponse> {
    let queryParams = `?limit=${payload.limit}`;
    queryParams = payload.cursor ? `${queryParams}&${IMPLICIT_PARTIES_PAYLOAD.CURSOR}=${payload.cursor}` : queryParams;
    queryParams = payload.searchValue
      ? `${queryParams}&${IMPLICIT_PARTIES_PAYLOAD.SEARCH_VALUE}=${encodeURIComponent(payload.searchValue)}`
      : queryParams;
    const payLoadDealerCode = `${payload.dealerCode}`;
    const URLs = `${APP_API_URLS.getImplicitParties.replace('{dealerCode}', payLoadDealerCode)}${queryParams}`.split('?');
    const handlerURL = URLs[0];
    let params = new HttpParams();
    if (payload.limit === 1) {
      params = new HttpParams().append('canShowSpinner', true);
    }
    const finalUrl = BASE_URL.HELIOS_BASE_URL + handlerURL + '?' + queryParams;
    return this.http.get<CustSummaryResponse>(finalUrl, { params });
  }

  getImplicitPartiesCount(payload: any): Observable<{ count: number }> {
    const queryParams = payload.searchValue ? `?${IMPLICIT_PARTIES_PAYLOAD.SEARCH_VALUE}=${encodeURIComponent(payload.searchValue)}` : '';
    const handlerURL = `${APP_API_URLS.getImplicitPartiesCount.replace('{dealerCode}', payload.dealerCode)}${queryParams}`;
    const params = new HttpParams();
    const finalUrl = BASE_URL.HELIOS_BASE_URL + handlerURL + '?' + queryParams;
    return this.http.get<{ count: number }>(finalUrl, { params });
  }

  getHeliosCustomPreference(): Observable<HeliosCustomPreference> {
    const URLs = APP_API_URLS.getCustomUserPreference.split('?');
    const handlerURL = URLs[0];
    const queryParams = URLs[1];
    const finalUrl = BASE_URL.HELIOS_BASE_URL + handlerURL + '?' + queryParams;
    const params = new HttpParams().append('canShowSpinner', 'true');
    return this.http.get<HeliosCustomPreference>(finalUrl, { params });
  }
  updateHeliosCustomPreference(payload: HeliosCustomPreference): Observable<any> {
    const handlerURL = this.envObj.heliosBaseURL + HELIOS_CUSTOM_PREF_ENDPOINT;
    const finalUrl = this.envObj.nodeBaseURL + NODE_SERVER.METHOD_NAME.PATCH;
    const body = {
      params: payload,
      handlerURL,
      customheaders: JSON.stringify({ 'Content-Type': 'application/merge-patch+json' })
    };
    return this.http.patch(finalUrl, body);
  }

  /**
   * method to get whether the user is having the pending request or not
   * @returns list of pending requests
   */
  getPendingUpgrade(catRecID: string): Observable<PendingUpgradeRequests> {
    const finalUrl = this.envObj.nodeBaseURL + NODE_SERVER.GET_PENDING_UPGRADE;
    const apiURl = APP_API_URLS.getPendingRequest.replace('{catRecID}', `${catRecID}`);
    const urlWithParams = apiURl.split('?');
    const body = {
      handlerURL: urlWithParams[0],
      payload: urlWithParams[1],
      post2Get: true
    };
    const params = new HttpParams().append('canShowSpinner', 'true');
    return this.http.post<PendingUpgradeRequests>(finalUrl, body, { params });
  }

  getHeliosGlobalUserPreference(): Observable<HeliosUserPreferenceResponse> {
    const URLs = APP_API_URLS.getGlobalUserPreference.split('?');
    const handlerURL = URLs[0];
    const queryParams = URLs[1];
    const finalUrl = BASE_URL.HELIOS_BASE_URL + handlerURL + '?' + queryParams;
    const params = new HttpParams().append('canShowSpinner', 'true');
    return this.http.get<HeliosUserPreferenceResponse>(finalUrl, { params });
  }

  constructHeliosGlobalUserPreference(preference: HeliosUserPreference): HeliosUserPreference {
    if (!preference) {
      return CP_DEFAULT_USER_GLOBAL_PREFERENCE_VALUE;
    }
    return {
      language: preference.language ? preference.language : CP_DEFAULT_USER_GLOBAL_PREFERENCE_VALUE.language,
      systemOfMeasurement: preference.systemOfMeasurement
        ? preference.systemOfMeasurement
        : CP_DEFAULT_USER_GLOBAL_PREFERENCE_VALUE.systemOfMeasurement,
      timeFormat: preference.timeFormat ? preference.timeFormat : CP_DEFAULT_USER_GLOBAL_PREFERENCE_VALUE.timeFormat,
      dateFormat: preference.dateFormat ? preference.dateFormat : CP_DEFAULT_USER_GLOBAL_PREFERENCE_VALUE.dateFormat,
      numberFormat: preference.numberFormat ? preference.numberFormat : CP_DEFAULT_USER_GLOBAL_PREFERENCE_VALUE.numberFormat
    };
  }

  userPreferenceValueMapping(preference: HeliosUserPreference): Preference {
    return {
      language: this.isPreferenceIsSupported(preference?.language ?? CP_DEFAULT_USER_GLOBAL_PREFERENCE_VALUE.language),
      unit: this.isPreferenceIsSupported(preference?.systemOfMeasurement ?? CP_DEFAULT_USER_GLOBAL_PREFERENCE_VALUE.systemOfMeasurement),
      timeFormat: this.isPreferenceIsSupported(preference?.timeFormat ?? CP_DEFAULT_USER_GLOBAL_PREFERENCE_VALUE.timeFormat),
      dateFormat: this.isPreferenceIsSupported(preference?.dateFormat ?? CP_DEFAULT_USER_GLOBAL_PREFERENCE_VALUE.dateFormat),
      decimalSeparator: this.isPreferenceIsSupported(preference?.numberFormat ?? CP_DEFAULT_USER_GLOBAL_PREFERENCE_VALUE.numberFormat)
    };
  }

  isPreferenceIsSupported(preference: string) {
    return preference && CP_USER_PREFERENCE_VALUE[preference]
      ? CP_USER_PREFERENCE_VALUE[preference]
      : CP_DEFAULT_USER_GLOBAL_PREFERENCE_VALUE[preference];
  }

  getNotifications(): Observable<NotificationResponse> {
    const headers = new HttpHeaders().append(xOrganizationId, String(3));
    const params = new HttpParams().append(NODE_SERVER.HANDLER_URL, APP_NOTIFICATION_HANDLER_URL).append('canShowSpinner', 'true');
    return this.http.get<NotificationResponse>(this.envObj.nodeBaseURL + NODE_SERVER.NOTIFICATION_ROUTE, { headers, params });
  }

  /**
   * Method to get bot secret key
   * @returns object of bot secret key
   */
  getBotSecretKey(): Observable<BotSecretKey> {
    const apiURL = '/getBotSecretKey';
    return this.http.get<BotSecretKey>(apiURL);
  }
}
