/* eslint-disable complexity */
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { StepperStepConfig } from '@Terra/shared/shared-lib';
import { API_STATES } from '@Terra/shared/widgets/config';
import { FeatureList, HeliosPreference, IndustryType, ProductFamilyConfig, SelectedParty, UserInfo } from '@Terra/shared/widgets/interface';
import { USER_ROLES_NAME } from '../appStore.config';
import { stepperWizard } from '../appStore.constants';
import { ImplicitPartiesCount, PartiesDetails, PrimaryParty, TimeZoneList } from '../models/app.model';
import { AppActions, AppActionTypes, ImplicitPartiesLoaded } from './app.actions';

/**
 * Interface for the 'App' data used in
 *  - AppState, and
 *  - appReducer
 */

/**
 * Interface to the part of the Store containing AppState
 * and other information related to AppData.
 */
export type IndustryTypeState = EntityState<IndustryType>;

const industryTypeAdapter: EntityAdapter<IndustryType> = createEntityAdapter<IndustryType>({});
export interface AppData {
  partiesFailed: any;
  siteWizardStep: StepperStepConfig;
  Features: FeatureList;
  enableUpgrade: boolean;
  industryTypes: IndustryTypeState;
  industryTypesFailed: boolean;
  productFamilyConfig: ProductFamilyConfig | API_STATES;
  timeZoneList: TimeZoneList[];
  timeZoneListError: boolean;
  isBrowserCompatible: boolean;
  partiesList: PartiesDetails;
  implicitPartiesList: PartiesDetails;
  implicitPartiesCount: ImplicitPartiesCount;
  implicitPartiesFailed: any;
  primaryPartyInfo: PrimaryParty;
  partiesSearchData: PartiesDetails;
  userInfo: UserInfo;
  selectedParty: SelectedParty;
  heliosPreferences: HeliosPreference;
  heliosUserRoles: { [key: string]: string };
  menuConfig: any;
}

export interface AppState {
  readonly app: AppData;
}

export const initialState: AppData = {
  siteWizardStep: stepperWizard,
  Features: null,
  enableUpgrade: false,
  industryTypes: industryTypeAdapter.getInitialState({}),
  industryTypesFailed: false,
  productFamilyConfig: {},
  timeZoneList: [],
  timeZoneListError: false,
  isBrowserCompatible: null,
  partiesList: null,
  implicitPartiesList: null,
  implicitPartiesCount: { count: null, isTotal: false },
  implicitPartiesFailed: null,
  primaryPartyInfo: null,
  partiesSearchData: null,
  userInfo: null,
  selectedParty: null,
  partiesFailed: null,
  heliosPreferences: null,
  heliosUserRoles: USER_ROLES_NAME,
  menuConfig: null
};

export function appReducer(state = initialState, action: AppActions): AppData {
  switch (action.type) {
    case AppActionTypes.GetPendingUpgradeResult: {
      return {
        ...state,
        enableUpgrade: action.payload
      };
    }

    case AppActionTypes.FeatureListLoaded: {
      return {
        ...state,
        Features: {
          features: action.payload,
          canRetry: action.canRetry
        }
      };
    }

    case AppActionTypes.ResetFeatureList: {
      return {
        ...state,
        Features: null
      };
    }

    case AppActionTypes.IndustryTypesLoaded:
      return { ...state, industryTypes: industryTypeAdapter.setAll(action.payload, state.industryTypes) };

    case AppActionTypes.IndustryTypeFailed: {
      return { ...state, industryTypesFailed: action.payload };
    }

    case AppActionTypes.ResetIndustryTypes:
      return { ...state, industryTypes: industryTypeAdapter.getInitialState({}) };

    case AppActionTypes.ResetAppState: {
      return {
        ...state,
        partiesList: null,
        implicitPartiesList: null,
        implicitPartiesCount: { count: null, isTotal: false },
        implicitPartiesFailed: null,
        primaryPartyInfo: null,
        partiesFailed: null,
        userInfo: null,
        selectedParty: null,
        heliosPreferences: null,
        siteWizardStep: stepperWizard,
        Features: null,
        industryTypesFailed: false,
        timeZoneList: [],
        timeZoneListError: false
      };
    }

    case AppActionTypes.ProductFamilyConfigLoadSuccess: {
      return { ...state, productFamilyConfig: action.payload };
    }

    case AppActionTypes.ProductFamilyConfigLoadFailure: {
      return { ...state, productFamilyConfig: API_STATES.API_ERROR };
    }

    case AppActionTypes.LoadTimeZoneListSuccess: {
      return {
        ...state,
        timeZoneList: action.payload
      };
    }
    case AppActionTypes.LoadTimeZoneListFailure: {
      return {
        ...state,
        timeZoneListError: action.payload
      };
    }

    case AppActionTypes.UpdateBrowserCompatibility: {
      return { ...state, isBrowserCompatible: action.payload };
    }

    //limit 1 represets search response for initial load. when limit is 1, we combine the response with already present parties list.
    //when isFirst100 is true we return the response as it is.
    //when limit is not 1 and isFirst100 false, then we'll get response for dropdown scroll, in that case, we combine the response with already present parties list.
    case AppActionTypes.PartiesLoaded: {
      return {
        ...state,
        partiesList:
          action.payload.limit === 1
            ? {
                ...state.partiesList,
                isFirst100: false,
                limit: 1,
                pickedBySearch: action.payload.pickedBySearch,
                parties: [...action.payload.parties, ...state.partiesList.parties]
              }
            : action.payload.isFirst100
            ? action.payload
            : {
                ...action.payload,
                parties: [...state.partiesList.parties, ...action.payload.parties]
              }
      };
    }

    //when reset is true, we are returning the parties from response as it is(since its first set of data).
    // else we combine it with already present search parites list (since this is for scroll after searching).
    case AppActionTypes.SearchPartiesLoaded: {
      return {
        ...state,
        partiesSearchData: {
          ...action.payload,
          parties:
            state.partiesSearchData?.parties && !action.payload.reset
              ? [...state.partiesSearchData.parties, ...action.payload.parties]
              : [...action.payload.parties]
        }
      };
    }

    case AppActionTypes.ClearSearchParties: {
      return {
        ...state,
        partiesSearchData: {
          parties: null,
          nextCursor: null,
          isClearParties: true
        }
      };
    }

    case AppActionTypes.ImplicitPartiesLoaded: {
      return {
        ...state,
        implicitPartiesList: getImplicitPartiesList(state, action)
      };
    }

    case AppActionTypes.ImplicitPartiesCountLoaded: {
      return { ...state, implicitPartiesCount: action.payload };
    }

    case AppActionTypes.LoadPrimaryPartyInfo: {
      return { ...state, primaryPartyInfo: action.payload };
    }

    case AppActionTypes.LoadSelectedParty: {
      return { ...state, selectedParty: action.payload };
    }

    case AppActionTypes.UserInfoLoaded: {
      return { ...state, userInfo: action.payload };
    }

    case AppActionTypes.PartiesFailed: {
      return { ...state, partiesFailed: action.payload };
    }

    case AppActionTypes.ImplicitPartiesFailed: {
      return { ...state, implicitPartiesFailed: action.payload };
    }

    case AppActionTypes.HeliosPreferencesLoaded: {
      return { ...state, heliosPreferences: action.payload };
    }

    case AppActionTypes.SetAppPreference: {
      return { ...state, heliosPreferences: { ...state.heliosPreferences, appPreference: action.payload } };
    }

    case AppActionTypes.LoadMenuConfig: {
      return { ...state, menuConfig: action.payload };
    }

    default:
      return state;
  }
}

export const getIndustryTypes = industryTypeAdapter.getSelectors().selectAll;

export function getImplicitPartiesList(state: AppData, action: ImplicitPartiesLoaded): any {
  return action.payload.limit === 1
    ? {
        nextCursor: action.payload.nextCursor,
        searchValue: action.payload.searchValue,
        limit: action.payload.prefSearchDone ? null : 1,
        parties: [...action.payload.parties, ...(state.implicitPartiesList?.parties ? state.implicitPartiesList.parties : [])],
        isPartySearchRequired: action.payload.isPartySearchRequired,
        prefSearchDone: action.payload.prefSearchDone
      }
    : action.payload.pickedBySearch || (action.payload.searchValue && !action.payload.currentCursor)
    ? {
        ...action.payload,
        parties: [...action.payload.parties]
      }
    : {
        ...action.payload,
        parties: [...state.implicitPartiesList.parties, ...action.payload.parties]
      };
}
