import { MENU_CONFIG } from './appStore.config';

export const MOCK_INDUSTRY_STORE_INFO = {
  id_0997: '09972290-DAF3-E911-B5E9-501AC51B1F7D',
  name_loadHaul: 'Loader - Load & Haul',
  id_4146: '4146017C-DC37-426C-9F75-7576028664AD',
  id_0E97: '0E972290-DAF3-E911-B5E9-501AC51B1F7D',
  id_0C97: '0C972290-DAF3-E911-B5E9-501AC51B1F7D',
  id_0D97: '0D972290-DAF3-E911-B5E9-501AC51B1F7D',
  id_0B97: '0B972290-DAF3-E911-B5E9-501AC51B1F7D',
  id_0A97: '0A972290-DAF3-E911-B5E9-501AC51B1F7D',
  name_loadandHaul: 'Hauler - Load & Haul',
  id_DF77: 'DF77F421-B068-4731-AFCC-5ABDBDE1BA51',
  key_dateRange: 'Date Range',
  id_E6EB: 'E6EBB726-478F-4D43-BD19-099B8AD64A3B',
  id_3035: '30356D41-60C9-415A-A92B-C2716EE371E2',
  id_EB2C: 'EB2C0D01-6B88-4618-96B4-89462DE532A4',
  id_f9ad: 'f9ad4597-3cfb-49aa-baea-39a6bc08ec63',
  name_totalCost: 'Total Cost',
  id_e667: 'e667c2db-9678-4ac7-bc19-4d5e709a36g',
  id_bfec: 'bfec1fab-2c9d-45ef-8695-cba255bee0db',
  id_5gd9: '5gd9c2db-9678-4ac7-bc19-4d5e709a36g',
  id_7e14: '7e142088-8321-42b5-aaa7-bedc2edd17c3',
  name_workingIdleHours: 'Working & Idle Hours',
  id_2067: '2067c2db-9218-4ac7-bc19-4d5e709a3ff',
  name_idle_fuel_burn: 'Working & Idle Fuel Burn',
  clientId_16c0: '16c04e27-e211-41a7-98ce-c0e12b598828',
  eTag_161118: "datetime'2022-11-16T10%3A40%3A18.6312572Z'",
  timeStap_161110: '2022-11-16T10:40:18.6312572Z',
  notification_cust_downTime_msg: 'Custom Downtime Message',
  notification_mock_downTime_msg: 'Mock Downtime Message'
};

export const INDUSTRY_TYPES_MOCK = [
  {
    id: '07972290-DAF3-E911-B5E9-501AC51B1F7D',
    name: 'Quarry',
    tags: [
      {
        id: MOCK_INDUSTRY_STORE_INFO.id_0997,
        name: MOCK_INDUSTRY_STORE_INFO.name_loadHaul
      },
      {
        id: MOCK_INDUSTRY_STORE_INFO.id_4146,
        name: 'Scraper'
      },
      {
        id: MOCK_INDUSTRY_STORE_INFO.id_0E97,
        name: 'Grade'
      },
      {
        id: MOCK_INDUSTRY_STORE_INFO.id_0C97,
        name: 'Support'
      },
      {
        id: MOCK_INDUSTRY_STORE_INFO.id_0D97,
        name: 'Compact'
      },
      {
        id: MOCK_INDUSTRY_STORE_INFO.id_0B97,
        name: 'Load Out'
      },
      {
        id: MOCK_INDUSTRY_STORE_INFO.id_0A97,
        name: MOCK_INDUSTRY_STORE_INFO.name_loadandHaul
      }
    ],
    zones: [
      {
        id: '13972290-DAF3-E911-B5E9-501AC51B1F7D',
        name: 'Exemption Zone'
      },
      {
        id: '11972290-DAF3-E911-B5E9-501AC51B1F7D',
        name: 'Fill/Dump Zone'
      },
      {
        id: '4EE7B13C-5FD2-4754-B75B-6CF5B115485B',
        name: 'Stockpile Zone'
      },
      {
        id: '15972290-DAF3-E911-B5E9-501AC51B1F7D',
        name: 'Site Boundary'
      },
      {
        id: '10972290-DAF3-E911-B5E9-501AC51B1F7D',
        name: 'Cut/Load Zone'
      }
    ],
    views: [
      {
        id: '07972290-DAF3-E911-B5E9-501AC51B1F7D',
        type: 'Summary',
        tabs: [
          {
            id: '37f1c0d6-53c2-45c6-bd9f-34368e6ef24b',
            name: 'Cost',
            order: 3,
            filterFacets: [
              {
                id: MOCK_INDUSTRY_STORE_INFO.id_DF77,
                key: MOCK_INDUSTRY_STORE_INFO.key_dateRange,
                values: [
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_E6EB,
                    name: 'today',
                    order: 1
                  },
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_3035,
                    name: 'yesterday',
                    order: 1
                  },
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_EB2C,
                    name: 'Last7days',
                    order: 1
                  }
                ]
              }
            ],
            tags: [
              {
                id: MOCK_INDUSTRY_STORE_INFO.id_0A97,
                name: MOCK_INDUSTRY_STORE_INFO.name_loadandHaul,
                order: 1,
                kpis: [
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_f9ad,
                    name: MOCK_INDUSTRY_STORE_INFO.name_totalCost,
                    order: 1,
                    view: ['Asset', 'Fleet']
                  }
                ]
              },
              {
                id: MOCK_INDUSTRY_STORE_INFO.id_0997,
                name: MOCK_INDUSTRY_STORE_INFO.name_loadHaul,
                order: 2,
                kpis: [
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_f9ad,
                    name: MOCK_INDUSTRY_STORE_INFO.name_totalCost,
                    order: 1,
                    view: ['Asset', 'Fleet']
                  }
                ]
              },
              {
                id: MOCK_INDUSTRY_STORE_INFO.id_0E97,
                name: 'Grade',
                order: 6,
                kpis: [
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_f9ad,
                    name: MOCK_INDUSTRY_STORE_INFO.name_totalCost,
                    order: 1,
                    view: ['Asset', 'Fleet']
                  }
                ]
              },
              {
                id: MOCK_INDUSTRY_STORE_INFO.id_0D97,
                name: 'Compact',
                order: 8,
                kpis: [
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_f9ad,
                    name: MOCK_INDUSTRY_STORE_INFO.name_totalCost,
                    order: 1,
                    view: ['Asset', 'Fleet']
                  }
                ]
              },
              {
                id: MOCK_INDUSTRY_STORE_INFO.id_0C97,
                name: 'Support',
                order: 5,
                kpis: [
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_f9ad,
                    name: MOCK_INDUSTRY_STORE_INFO.name_totalCost,
                    order: 1,
                    view: ['Asset', 'Fleet']
                  }
                ]
              },
              {
                id: MOCK_INDUSTRY_STORE_INFO.id_4146,
                name: 'Scraper',
                order: 4,
                kpis: [
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_f9ad,
                    name: MOCK_INDUSTRY_STORE_INFO.name_totalCost,
                    order: 1,
                    view: ['Asset', 'Fleet']
                  }
                ]
              },
              {
                id: MOCK_INDUSTRY_STORE_INFO.id_0B97,
                name: 'Load Out',
                order: 3,
                kpis: [
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_f9ad,
                    name: MOCK_INDUSTRY_STORE_INFO.name_totalCost,
                    order: 1,
                    view: ['Asset', 'Fleet']
                  }
                ]
              }
            ]
          },
          {
            id: 'dc59a0d3-eb34-4951-99e8-67dafed82dcd',
            name: 'Production',
            order: 1,
            filterFacets: [
              {
                id: MOCK_INDUSTRY_STORE_INFO.id_DF77,
                key: MOCK_INDUSTRY_STORE_INFO.key_dateRange,
                values: [
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_E6EB,
                    name: 'today',
                    order: 1
                  },
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_3035,
                    name: 'yesterday',
                    order: 1
                  },
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_EB2C,
                    name: 'Last7days',
                    order: 1
                  }
                ]
              }
            ],
            tags: [
              {
                id: MOCK_INDUSTRY_STORE_INFO.id_0D97,
                name: 'Compact',
                order: 8,
                kpis: [
                  {
                    name: 'Measured Volume',
                    id: 'cf7b4830-c778-4b98-a735-04be4a78faf5',
                    order: 5,
                    view: ['Asset']
                  }
                ]
              },
              {
                id: MOCK_INDUSTRY_STORE_INFO.id_4146,
                name: 'Scraper',
                order: 4,
                kpis: [
                  {
                    name: 'LoadCount',
                    id: MOCK_INDUSTRY_STORE_INFO.id_e667,
                    order: 1,
                    view: ['Asset', 'Fleet']
                  },
                  {
                    name: 'Payload',
                    id: MOCK_INDUSTRY_STORE_INFO.id_bfec,
                    order: 2,
                    view: ['Asset', 'Fleet']
                  },
                  {
                    name: 'Segments',
                    id: MOCK_INDUSTRY_STORE_INFO.id_5gd9,
                    order: 3,
                    view: ['donut']
                  },
                  {
                    name: 'Estimated Volume',
                    id: '6he9d2db-9678-4ac7-bc19-4d5e709a36g',
                    order: 4,
                    view: ['Asset', 'Fleet']
                  }
                ]
              },
              {
                id: MOCK_INDUSTRY_STORE_INFO.id_0E97,
                name: 'Grade',
                order: 6,
                kpis: [
                  {
                    name: 'Measured Volume',
                    id: 'cf7b4830-c778-4b98-a735-04be4a78faf5',
                    order: 5,
                    view: ['Asset']
                  }
                ]
              },
              {
                id: MOCK_INDUSTRY_STORE_INFO.id_0997,
                name: MOCK_INDUSTRY_STORE_INFO.name_loadHaul,
                order: 2,
                kpis: [
                  {
                    name: 'LoadCount',
                    id: MOCK_INDUSTRY_STORE_INFO.id_e667,
                    order: 1,
                    view: ['Asset', 'Fleet']
                  },
                  {
                    name: 'Payload',
                    id: MOCK_INDUSTRY_STORE_INFO.id_bfec,
                    order: 2,
                    view: ['Asset', 'Fleet']
                  },
                  {
                    name: 'Segments',
                    id: MOCK_INDUSTRY_STORE_INFO.id_5gd9,
                    order: 3,
                    view: ['donut']
                  }
                ]
              },
              {
                id: MOCK_INDUSTRY_STORE_INFO.id_0B97,
                name: 'Load Out',
                order: 3,
                kpis: [
                  {
                    name: 'LoadCount',
                    id: MOCK_INDUSTRY_STORE_INFO.id_e667,
                    order: 1,
                    view: ['Asset', 'Fleet']
                  },
                  {
                    name: 'Payload',
                    id: MOCK_INDUSTRY_STORE_INFO.id_bfec,
                    order: 2,
                    view: ['Asset', 'Fleet']
                  },
                  {
                    name: 'Segments',
                    id: MOCK_INDUSTRY_STORE_INFO.id_5gd9,
                    order: 3,
                    view: ['donut']
                  }
                ]
              },
              {
                id: MOCK_INDUSTRY_STORE_INFO.id_0A97,
                name: MOCK_INDUSTRY_STORE_INFO.name_loadandHaul,
                order: 1,
                kpis: [
                  {
                    name: 'LoadCount',
                    id: MOCK_INDUSTRY_STORE_INFO.id_e667,
                    order: 1,
                    view: ['Asset', 'Fleet']
                  },
                  {
                    name: 'Payload',
                    id: MOCK_INDUSTRY_STORE_INFO.id_bfec,
                    order: 2,
                    view: ['Asset', 'Fleet']
                  },
                  {
                    name: 'Segments',
                    id: MOCK_INDUSTRY_STORE_INFO.id_5gd9,
                    order: 3,
                    view: ['donut']
                  },
                  {
                    name: 'Estimated Volume',
                    id: '6he9d2db-9678-4ac7-bc19-4d5e709a36g',
                    order: 4,
                    view: ['Asset', 'Fleet']
                  }
                ]
              },
              {
                id: MOCK_INDUSTRY_STORE_INFO.id_0C97,
                name: 'Support',
                order: 5,
                kpis: []
              }
            ]
          },
          {
            id: 'edd1dd73-584b-472c-afd7-d9479e6ab82',
            name: 'Utilization',
            order: 2,
            filterFacets: [
              {
                id: MOCK_INDUSTRY_STORE_INFO.id_DF77,
                key: MOCK_INDUSTRY_STORE_INFO.key_dateRange,
                values: [
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_E6EB,
                    name: 'today',
                    order: 1
                  },
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_3035,
                    name: 'yesterday',
                    order: 1
                  },
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_EB2C,
                    name: 'Last7days',
                    order: 1
                  }
                ]
              }
            ],
            tags: [
              {
                id: MOCK_INDUSTRY_STORE_INFO.id_0E97,
                name: 'Grade',
                order: 6,
                kpis: [
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_7e14,
                    name: MOCK_INDUSTRY_STORE_INFO.name_workingIdleHours,
                    order: 1
                  },
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_2067,
                    name: MOCK_INDUSTRY_STORE_INFO.name_idle_fuel_burn,
                    order: 2
                  }
                ]
              },
              {
                id: MOCK_INDUSTRY_STORE_INFO.id_4146,
                name: 'Scraper',
                order: 4,
                kpis: [
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_7e14,
                    name: MOCK_INDUSTRY_STORE_INFO.name_workingIdleHours,
                    order: 1
                  },
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_2067,
                    name: MOCK_INDUSTRY_STORE_INFO.name_idle_fuel_burn,
                    order: 2
                  }
                ]
              },
              {
                id: MOCK_INDUSTRY_STORE_INFO.id_0D97,
                name: 'Compact',
                order: 8,
                kpis: [
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_7e14,
                    name: MOCK_INDUSTRY_STORE_INFO.name_workingIdleHours,
                    order: 1
                  },
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_2067,
                    name: MOCK_INDUSTRY_STORE_INFO.name_idle_fuel_burn,
                    order: 2
                  }
                ]
              },
              {
                id: MOCK_INDUSTRY_STORE_INFO.id_0C97,
                name: 'Support',
                order: 5,
                kpis: [
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_7e14,
                    name: MOCK_INDUSTRY_STORE_INFO.name_workingIdleHours,
                    order: 1
                  },
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_2067,
                    name: MOCK_INDUSTRY_STORE_INFO.name_idle_fuel_burn,
                    order: 2
                  }
                ]
              },
              {
                id: MOCK_INDUSTRY_STORE_INFO.id_0997,
                name: MOCK_INDUSTRY_STORE_INFO.name_loadHaul,
                order: 2,
                kpis: [
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_7e14,
                    name: MOCK_INDUSTRY_STORE_INFO.name_workingIdleHours,
                    order: 1
                  },
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_2067,
                    name: MOCK_INDUSTRY_STORE_INFO.name_idle_fuel_burn,
                    order: 2
                  }
                ]
              },
              {
                id: MOCK_INDUSTRY_STORE_INFO.id_0A97,
                name: MOCK_INDUSTRY_STORE_INFO.name_loadandHaul,
                order: 1,
                kpis: [
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_7e14,
                    name: MOCK_INDUSTRY_STORE_INFO.name_workingIdleHours,
                    order: 1
                  },
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_2067,
                    name: MOCK_INDUSTRY_STORE_INFO.name_idle_fuel_burn,
                    order: 2
                  }
                ]
              },
              {
                id: MOCK_INDUSTRY_STORE_INFO.id_0B97,
                name: 'Load Out',
                order: 3,
                kpis: [
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_7e14,
                    name: MOCK_INDUSTRY_STORE_INFO.name_workingIdleHours,
                    order: 1
                  },
                  {
                    id: MOCK_INDUSTRY_STORE_INFO.id_2067,
                    name: MOCK_INDUSTRY_STORE_INFO.name_idle_fuel_burn,
                    order: 2
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];

export const MockUserInfo = {
  c: null,
  cataffiliation: null,
  catafltncode: '000',
  catafltnorgcode: 'AssociatedorgcodeNotPresent',
  catbusinessmail: null,
  catcupid: '3058146697',
  catloginid: 'CAT1234',
  catrecid: 'PSP-0000FDN0',
  catworklocdesc: '',
  displayname: 'CAT_Test',
  distinguishedname: '',
  given_name: 'CAT_Test',
  mail: 'Test@CAT.com',
  o: null,
  registeredname: null,
  family_name: 'CAT',
  sub: null,
  uniquememberof: null,
  catafltnclass: 'SUPP',
  cattoplevelorgcode: 'TopLevelOrgCodeNotPresent',
  catassociatedorgcode: 'AssociatedorgcodeNotPresent',
  email_address: 'Test@CAT.com',
  name: 'CAT',
  country: 'IN',
  company: 'PVT ltd',
  client_id: MOCK_INDUSTRY_STORE_INFO.clientId_16c0,
  language: 'en',
  tid: '4f0f19d0-f44c-4a03-b8cb-ab327bd2b12b',
  tfp: 'B2C_1A_P2_V1_SignIn_Staging',
  scp: 'read',
  azp: MOCK_INDUSTRY_STORE_INFO.clientId_16c0,
  ver: '1.0',
  iat: 1685444535,
  aud: MOCK_INDUSTRY_STORE_INFO.clientId_16c0,
  exp: 1685448135,
  iss: null,
  nbf: 1685444535,
  userType: 'CUST',
  sn: '',
  telephonenumber: '2456876',
  ucid: '',
  givenname: 'CATTest'
};

export const MockPartiesDetails = {
  parties: [
    {
      isPrimary: true,
      partyNumber: 'CAT',
      partyType: 'CAT',
      partyName: 'CAT Customer',
      roleId: 'cf361de7-a3b5-41c8-af3e-87684f99f11f',
      roleName: 'CAT Administrator'
    }
  ],
  nextCursor: null,
  isFirst100: false,
  limit: 1,
  pickedBySearch: false,
  skipModalClose: false,
  isClearParties: false
};

export const MockImplicitParty = {
  partyNumber: '2972127340',
  partyName: 'SIT_CUST_ORG_0035',
  isPrimary: true,
  partyType: 'DLR',
  roleId: 'c5bb952b-bc28-4969-8775-834cd5e928eb',
  roleName: 'Standard'
};

export const MockSelectedParty = {
  ucid: '2972127340',
  userRoleID: 'c5bb952b-bc28-4969-8775-834cd5e928eb',
  userRoleName: 'Standard',
  applicationID: 3,
  partyName: 'SIT_CUST_ORG_0035',
  isPrimary: true,
  catRecID: 'PSP-0000FDN0',
  cwsid: 'CAT1234'
};

export const MockPartiesResponse = {
  parties: [MockImplicitParty],
  userInfo: MockUserInfo
};

export const MockDealerPayload = {
  sessionUcid: '2972127340',
  userInfo: { ...MockUserInfo, catafltncode: '005', catafltnorgcode: 'TD00', userType: 'DLR' }
};

export const MockImplicitParties = {
  parties: [
    {
      partyNumber: '2972127340',
      partyName: 'SIT_CUST_ORG_0035',
      assetCount: 240,
      enrolledUsersCount: 4,
      userInvitesCount: 0,
      pendingAccessRequests: 0,
      userInvitationsCount: 0
    },
    {
      partyNumber: '2972127341',
      partyName: '2972127341',
      assetCount: 240,
      enrolledUsersCount: 4,
      userInvitesCount: 0,
      pendingAccessRequests: 0,
      userInvitationsCount: 0
    }
  ],
  responseMetadata: {
    nextCursor: 'eyJvZmZzZXQiOjIwOH0='
  }
};

export const GLOBAL_PREF_MOCK_DATA = {
  defaults: {
    assetLabel: 'name',
    assetLocationLabel: 'address',
    currency: 'USD',
    dateFormat: 'mmddyyyy',
    language: 'en-US',
    numberFormat: 'radixDot',
    pressureUnit: 'psi',
    systemOfMeasurement: 'usStandard',
    temperatureUnit: 'F',
    timeFormat: 'hours24',
    timeZone: 'Europe/London',
    windowsTimeZone: 'GMT Standard Time'
  },
  userProvided: {
    dateFormat: 'mmmddyyyy',
    language: 'en-US',
    numberFormat: 'radixDot',
    systemOfMeasurement: 'usStandard',
    timeFormat: 'hours12'
  }
};
export const MockHeliosPreferences = {
  appPreference: {
    language: 'en-us',
    unit: 'U.S. Customary',
    timeFormat: '12 hour',
    dateFormat: 'MMM DD, YYYY',
    decimalSeparator: 'Period',
    ucid: '2969600765',
    favoriteSites: '"\\"{\\\\\\"2972056560\\\\\\":[]}\\""',
    defaultPreferenceCP: false
  },
  globalPreference: {
    language: 'en-US',
    systemOfMeasurement: 'usStandard',
    timeFormat: 'hours12',
    dateFormat: 'mmmddyyyy',
    numberFormat: 'radixDot',
    rawData: GLOBAL_PREF_MOCK_DATA
  }
};

class DynamicDate {
  createDate(days: number): string {
    const now = new Date();
    if (days) {
      now.setDate(now.getDate() + days);
    }
    const [date, month, hour, second] = [now.getUTCDate(), now.getUTCMonth() + 1, now.getUTCHours(), now.getUTCMinutes()].map(item =>
      item < 10 ? `0${item}` : item
    );
    return `${now.getUTCFullYear()}-${month}-${date}T${hour}:${second}:00Z`;
  }
}
export const dyDate = new DynamicDate();
export const MOCK_NOTIFICATIONS = {
  ALL_ACTIVE: [
    {
      appName: 'CatProductivity',
      notificationEndTime: dyDate.createDate(1),
      notificationMessage: MOCK_INDUSTRY_STORE_INFO.notification_cust_downTime_msg,
      notificationStartTime: dyDate.createDate(0),
      notificationType: 'Custom',
      detailRow: true,
      displayTime: 'Jan 23, 2023 08:16 AM UTC to Jan 23, 2023 11:16 AM UTC',
      tableName: 'appnotification'
    },
    {
      appName: 'CatProductivity',
      notificationEndTime: dyDate.createDate(6),
      notificationMessage: MOCK_INDUSTRY_STORE_INFO.notification_mock_downTime_msg,
      notificationStartTime: dyDate.createDate(3),
      notificationType: 'Downtime',
      detailRow: true,
      displayTime: 'Jan 23, 2023 08:16 AM UTC to Jan 23, 2023 11:16 AM UTC',
      tableName: 'appnotification'
    }
  ],
  CUSTOM_ACTIVE: [
    {
      appName: 'CatProductivity',
      notificationEndTime: dyDate.createDate(1),
      notificationMessage: MOCK_INDUSTRY_STORE_INFO.notification_cust_downTime_msg,
      notificationStartTime: dyDate.createDate(0),
      notificationType: 'Custom',
      detailRow: true,
      displayTime: 'Jan 23, 2023 08:16 AM UTC to Jan 23, 2023 11:16 AM UTC',
      tableName: 'appnotification'
    },
    {
      appName: 'CatProductivity',
      notificationEndTime: dyDate.createDate(-1),
      notificationMessage: MOCK_INDUSTRY_STORE_INFO.notification_mock_downTime_msg,
      notificationStartTime: dyDate.createDate(-4),
      notificationType: 'Downtime',
      detailRow: true,
      displayTime: 'Jan 23, 2023 08:16 AM UTC to Jan 23, 2023 11:16 AM UTC',
      tableName: 'appnotification'
    }
  ],
  DOWNTIME_ACTIVE: [
    {
      appName: 'CatProductivity',
      notificationEndTime: dyDate.createDate(-1),
      notificationMessage: MOCK_INDUSTRY_STORE_INFO.notification_cust_downTime_msg,
      notificationStartTime: dyDate.createDate(-2),
      notificationType: 'Custom',
      detailRow: true,
      displayTime: 'Jan 23, 2023 08:16 AM UTC to Jan 23, 2023 11:16 AM UTC',
      tableName: 'appnotification'
    },
    {
      appName: 'CatProductivity',
      notificationEndTime: dyDate.createDate(5),
      notificationMessage: MOCK_INDUSTRY_STORE_INFO.notification_mock_downTime_msg,
      notificationStartTime: dyDate.createDate(2),
      notificationType: 'Downtime',
      detailRow: true,
      displayTime: 'Jan 23, 2023 08:16 AM UTC to Jan 23, 2023 11:16 AM UTC',
      tableName: 'appnotification'
    }
  ]
};

export const PREFERENCE = {
  autoRefresh: 'Disabled',
  dateFormat: 'MMM DD, YYYY',
  decimalSeparator: 'Period',
  defaultPreferenceCP: false,
  favoriteSites: { '2972056560': [] },
  ucid: '2969600765',
  language: 'en-us',
  remember: false,
  timeFormat: '12 hour',
  unit: 'U.S. Customary'
};
export const MockMenuItems = [
  {
    ...MENU_CONFIG.dashboard,
    name: 'en.Dashboard',
    children: [
      {
        ...MENU_CONFIG.dashboard.children[0],
        name: 'en.Production'
      }
    ]
  },
  {
    ...MENU_CONFIG.allAssets,
    name: 'en.Assets',
    children: []
  },
  {
    ...MENU_CONFIG.manage,
    name: 'en.Manage',
    children: [
      {
        ...MENU_CONFIG.manage.children[0],
        name: 'en.Targets'
      },
      {
        ...MENU_CONFIG.manage.children[1],
        name: 'en.Users'
      },
      {
        ...MENU_CONFIG.manage.children[2],
        name: 'en.Reports'
      },
      {
        ...MENU_CONFIG.manage.children[3],
        name: 'en.Asset Services'
      },
      {
        ...MENU_CONFIG.manage.children[4],
        name: 'en.Audit Logs'
      }
    ]
  }
];

export const MockAppNotification = [
  {
    id: 1,
    title: 'Custom',
    message: 'message',
    error: 'error',
    payload: {
      siteName: 'siteName',
      reportType: 'veta'
    }
  }
];

export const MockNonPrimaryPartiesDetails = {
  parties: [
    {
      partyNumber: '2972056559',
      partyName: 'CDT APPS INT TEST ACCOUNT 2',
      partyType: 'CAT',
      roleId: 'cf361de7-a3b5-41c8-af3e-87684f99f11f',
      roleName: 'CAT Administrator'
    }
  ],
  nextCursor: null,
  isFirst100: false,
  limit: 1,
  pickedBySearch: false,
  skipModalClose: false,
  isClearParties: false
};
