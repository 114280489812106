import { GlobalUtilService, isDemoModeON } from '@Terra/pitmanagement/shared/utils';
import {
  CP_DEFAULT_USER_CUSTOM_PREFERENCE_VALUE,
  CP_DEFAULT_USER_GLOBAL_PREFERENCE_VALUE,
  HeliosCustomPreference
} from '@Terra/shared/shared-lib';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { CONSENT_STATUS, HOSTNAME_TO_ENV_CONFIG, MAP_UCID } from '@Terra/shared/widgets/config';
import { HeliosUserPreferenceResponse, UserInfo } from '@Terra/shared/widgets/interface';
import { forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { AppUtilService } from '../app-util.service';
import { AppService } from '../app.service';
import {
  APP_ENV,
  CP_APPLICATION_ID,
  PARTY,
  PARTY_FILTER_KEY,
  PARTY_SEARCH_DEFAULT_LIMIT,
  PARTY_SEARCH_INIT_LIMIT,
  USER_TYPES
} from '../appStore.config';
import { ConsentInfo, CustSummary, CustSummaryResponse, PartiesDetails, Party, PendingUpgradeRequests } from '../models/app.model';
import * as appActions from './app.actions';
import { AppActionTypes } from './app.actions';

@Injectable()
export class AppEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly appService: AppService,
    private readonly globalUtilService: GlobalUtilService,
    private readonly appUtilService: AppUtilService
  ) {}

  //Get Parties & Helios Preferences Data on Initial Load
  getPartiesAndPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActionTypes.GetPartiesAndPreferences),
      switchMap((action: appActions.GetPartiesAndPreferences) => {
        if (!action.payload.userInfo?.userType) {
          //this block helps redirect user to marketing page when terra auth api fails in node layer itself (userType will be '' in this scenario)
          return of(new appActions.PartiesFailed({ failedStatus: 401, isCustSummary: false }));
        }
        if (action.payload.userInfo?.userType === USER_TYPES.DEALER) {
          return this.getPartiesAndPreferenceForDealer(action.payload.sessionUcid, action.payload.userInfo);
        } else {
          return this.getPartiesAndPreferenceForNonDealer(action.payload.sessionUcid, action.payload.userInfo);
        }
      })
    )
  );

  // Get parties list on Search / Scroll scenarios.
  getPartiesDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActionTypes.GetPartiesDetails),
      switchMap((action: appActions.GetPartiesDetails) =>
        this.appService.getCustSummary({ ...action.payload, limit: 100 }).pipe(
          map(data => {
            const partiesDetails = this.appUtilService.appendRoot(
              data.body,
              action.payload.roleId,
              action.payload.roleName,
              action.payload.searchValue
            );
            if (action.payload.searchValue && action.payload.limit !== PARTY_SEARCH_INIT_LIMIT) {
              // response for parties dropdown search
              return new appActions.SearchPartiesLoaded({
                parties: partiesDetails.parties,
                nextCursor: data.body.responseMetadata?.nextCursor,
                reset: !!action.payload.reset,
                skipModalClose: true
              });
            } else {
              return new appActions.PartiesLoaded({
                parties: partiesDetails.parties,
                nextCursor: data.body.responseMetadata?.nextCursor,
                limit: action.payload.limit,
                skipModalClose: true
              });
            }
          }),
          catchError(error => {
            if (action.payload.searchValue && error.status === 404 && action.payload.limit !== PARTY_SEARCH_INIT_LIMIT) {
              //in case of no records found for dropdown search, returning empty array
              return of(
                new appActions.SearchPartiesLoaded({
                  parties: [],
                  nextCursor: null,
                  reset: !!action.payload.reset,
                  skipModalClose: true
                })
              );
            } else {
              if (action.payload.limit === PARTY_SEARCH_INIT_LIMIT) {
                //in case of no records found for initial load search, returning empty array
                return of(
                  new appActions.PartiesLoaded({
                    parties: [],
                    nextCursor: null,
                    limit: action.payload.limit,
                    skipModalClose: true
                  })
                );
              } else {
                //in case of other api errors for scroll or dropdown search, below action dispatched to show fetch error & try again option.
                return of(new appActions.PartiesFailed({ failedStatus: error.status, isCustSummary: true }));
              }
            }
          })
        )
      )
    )
  );

  getImplicitParties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActionTypes.LoadImplicitParties),
      switchMap((action: appActions.LoadImplicitParties) =>
        this.appService.getImplicitParties(action.payload).pipe(
          map(
            implicitParties =>
              new appActions.ImplicitPartiesLoaded({
                parties: this.appUtilService.updateImplicitParties(implicitParties.parties),
                currentCursor: action.payload.cursor,
                nextCursor: implicitParties.responseMetadata?.nextCursor,
                limit: action.payload.limit,
                searchValue: !!action.payload.searchValue
              })
          ),
          catchError(error => {
            if (action.payload.searchValue && error.status === 404) {
              return of(
                new appActions.ImplicitPartiesLoaded({
                  parties: [],
                  nextCursor: null,
                  limit: action.payload.limit,
                  searchValue: !!action.payload.searchValue
                })
              );
            } else {
              return of(new appActions.ImplicitPartiesFailed(error));
            }
          })
        )
      )
    )
  );

  getImplicitPartiesCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActionTypes.LoadImplicitPartiesCount),
      switchMap((action: appActions.LoadImplicitPartiesCount) =>
        this.appService.getImplicitPartiesCount(action.payload).pipe(
          map(response => new appActions.ImplicitPartiesCountLoaded({ count: response.count, isTotal: action.payload.isInitial })),
          catchError(error => of(new appActions.ImplicitPartiesCountLoaded({ count: null, isTotal: false })))
        )
      )
    )
  );

  feature$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActionTypes.LoadFeatureList),
      switchMap((action: appActions.LoadFeatureListAction) =>
        this.appService.getFeaturesList(action.ucid, action.canRetry).pipe(
          map(viewFeatureData => new appActions.FeatureListLoadedAction(viewFeatureData.features, action.canRetry)),
          catchError(error => of(new appActions.FeatureListLoadedAction([], action.canRetry)))
        )
      )
    )
  );

  fetchIndustryTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActionTypes.LoadIndustryTypes),
      switchMap((action: appActions.LoadIndustryTypesAction) =>
        this.appService.getIndustryTypes(action.payload).pipe(
          map(industryTypes => new appActions.IndustryTypesLoadedAction(industryTypes)),
          catchError(error => of(new appActions.IndustryTypeFailedAction(true)))
        )
      )
    )
  );

  GetPendingUpgrade$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActionTypes.GetPendingUpgrade),
      switchMap((action: appActions.GetPendingUpgrade) =>
        this.appService.getPendingUpgrade(action.payload).pipe(
          map(
            (pendingUpgrade: PendingUpgradeRequests) =>
              new appActions.GetPendingUpgradeResult(pendingUpgrade.serviceRequest.length ? false : true)
          ),
          catchError(_ => of(new appActions.GetPendingUpgradeResult(false)))
        )
      )
    )
  );

  GetProductFamilyConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActionTypes.LoadProductFamilyConfig),
      switchMap((action: appActions.LoadProductFamilyConfigAction) =>
        this.appService.getProductFamilyConfig().pipe(
          map(productFamily => new appActions.ProductFamilyConfigLoadSuccessAction(productFamily)),
          catchError(error => of(new appActions.ProductFamilyConfigLoadFailureAction(error)))
        )
      )
    )
  );

  timezoneList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActionTypes.LoadTimeZoneList),
      switchMap((action: appActions.LoadTimeZoneListAction) =>
        this.appService.getTimeZoneList().pipe(
          map(response => new appActions.LoadTimeZoneListSuccessAction(response)),
          catchError(error => of(new appActions.LoadTimeZoneListFailureAction(true)))
        )
      )
    )
  );

  updateHeliosCustomPreference$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActionTypes.UpdateHeliosCustomPreference),
      switchMap((action: appActions.UpdateHeliosCustomPreference) =>
        this.appService.updateHeliosCustomPreference(action.payload).pipe(
          map(response => {
            const preference = this.globalUtilService.preferenceData;
            return new appActions.SetAppPreference(preference);
          }),
          catchError(error => {
            const preference = this.globalUtilService.preferenceData;
            return of(new appActions.SetAppPreference(preference));
          })
        )
      )
    )
  );

  // Get Party stored in session and in helios custom preference if its not present in first 100 cust summary response
  getSessionAndHeliosParty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActionTypes.GetSessionAndHeliosParty),
      switchMap((action: appActions.GetSessionAndHeliosParty) => {
        const getSesssionParty = this.getSessionAndHeliosParty(
          this.prepareSearchPartyPayload(action.payload.parties[0], action.payload.sessionParty)
        );
        const getHeliosParty = this.getSessionAndHeliosParty(
          this.prepareSearchPartyPayload(action.payload.parties[0], action.payload.heliosParty)
        );
        return forkJoin({ getSesssionParty, getHeliosParty }).pipe(
          mergeMap(response => {
            let partiesResult = [];
            if (response.getSesssionParty.parties?.length) {
              partiesResult.push(response.getSesssionParty.parties[0]);
            }
            if (response.getHeliosParty.parties?.length) {
              partiesResult.push(response.getHeliosParty.parties[0]);
            }
            partiesResult = partiesResult.map(party => ({
              ...party,
              partyType: PARTY.PARTY_NUMBER.CAT,
              roleId: action.payload.parties[0].roleId,
              roleName: action.payload.parties[0].roleName
            }));
            return [
              new appActions.PartiesLoaded({
                parties: partiesResult,
                nextCursor: null,
                limit: PARTY_SEARCH_INIT_LIMIT,
                skipModalClose: true
              })
            ];
          })
        );
      })
    )
  );

  findImplicitPartyDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActionTypes.FindImplicitPartyDetails),
      switchMap((action: appActions.FindImplicitPartyDetails) =>
        this.appService
          .getImplicitParties({
            searchValue: action.payload.partyNumber,
            dealerCode: action.payload.userInfo.catafltnorgcode,
            limit: PARTY_SEARCH_INIT_LIMIT
          })
          .pipe(
            map(
              response =>
                new appActions.ImplicitPartiesLoaded({
                  parties: this.appUtilService.updateImplicitParties(response.parties),
                  limit: PARTY_SEARCH_INIT_LIMIT,
                  prefSearchDone: true
                })
            ),
            catchError(error =>
              of(
                new appActions.ImplicitPartiesLoaded({
                  parties: [],
                  limit: PARTY_SEARCH_INIT_LIMIT,
                  prefSearchDone: true
                })
              )
            )
          )
      )
    )
  );

  getPartiesAndPreferenceForNonDealer(sessionUcid: string, userInfo: UserInfo) {
    const partiesAndPermissions = this.getPartiesAndPermission();
    const heliosCustomPreference = this.getHeliosCustomPreference();
    const heliosGlobalUserPreference = this.getHeliosGlobalUserPreference();
    const consentInfo = this.getConsentStatus();
    return forkJoin({ partiesAndPermissions, heliosCustomPreference, heliosGlobalUserPreference, consentInfo }).pipe(
      mergeMap(response => {
        if (!response.partiesAndPermissions.parties.length || (response.consentInfo.isFailed && response.heliosCustomPreference.isFailed)) {
          return [new appActions.PartiesFailed({ failedStatus: 401, isCustSummary: false })];
        } else {
          this.appUtilService.legalUtil.loadConsentStatus(response.heliosCustomPreference, response.consentInfo);
          const preference = this.appUtilService.formPreference(
            response.heliosCustomPreference,
            response.heliosGlobalUserPreference,
            response.partiesAndPermissions
          );
          const ucid = sessionUcid ? sessionUcid : preference.appPreference.ucid;
          const defaultParty = response.partiesAndPermissions.parties.filter(party => party.partyNumber === ucid)[0];
          const selectedParty = {
            ucid,
            userRoleID: defaultParty?.roleId,
            userRoleName: defaultParty?.roleName,
            applicationID: CP_APPLICATION_ID,
            partyName: defaultParty?.partyName,
            isPrimary: defaultParty?.isPrimary,
            catRecID: userInfo.catrecid,
            cwsid: userInfo.catloginid
          };
          return [
            new appActions.LoadSelectedParty(selectedParty),
            new appActions.HeliosPreferencesLoaded(preference),
            new appActions.PartiesLoaded(response.partiesAndPermissions),
            new appActions.LoadPrimaryPartyInfo({ ...response.partiesAndPermissions.primaryPartyInfo, userType: userInfo.userType })
          ];
        }
      }),
      catchError(error => of(new appActions.PartiesFailed({ failedStatus: error?.status, isCustSummary: false })))
    );
  }

  getPartiesAndPreferenceForDealer(sessionUcid: string, userInfo: UserInfo) {
    const partiesAndPermissions = this.getPartiesAndPermission();
    const heliosCustomPreference = this.getHeliosCustomPreference();
    const heliosGlobalUserPreference = this.getHeliosGlobalUserPreference();
    const consentInfo = this.getConsentStatus();
    let heliosApis: any = { partiesAndPermissions, heliosCustomPreference, heliosGlobalUserPreference, consentInfo };
    if (sessionUcid) {
      heliosApis = {
        partiesAndPermissions,
        heliosCustomPreference,
        heliosGlobalUserPreference,
        consentInfo,
        implicitParty: this.findImplicitParty(sessionUcid, userInfo.catafltnorgcode)
      };
    }
    return forkJoin(heliosApis).pipe(
      mergeMap((response: any) => {
        if (!response.partiesAndPermissions.parties.length || (response.consentInfo.isFailed && response.heliosCustomPreference.isFailed)) {
          return [new appActions.PartiesFailed({ failedStatus: 401, isCustSummary: false })];
        } else {
          this.appUtilService.legalUtil.loadConsentStatus(response.heliosCustomPreference, response.consentInfo);
          const heliosPreferenceUcid = response.heliosCustomPreference.ucid;
          const isPartySearchNotRequired = () =>
            this.appUtilService.findPartyDetailsByValue(
              response.partiesAndPermissions.parties,
              PARTY_FILTER_KEY.PARTY_NUMBER,
              heliosPreferenceUcid
            );
          const ucid = sessionUcid ? sessionUcid : heliosPreferenceUcid;
          let defaultParty = this.appUtilService.findPartyDetailsByValue(
            response.partiesAndPermissions.parties,
            PARTY_FILTER_KEY.PARTY_NUMBER,
            ucid
          );
          if (!defaultParty && sessionUcid) {
            defaultParty = this.appUtilService.findPartyDetailsByValue(
              response.implicitParty?.parties,
              PARTY_FILTER_KEY.PARTY_NUMBER,
              sessionUcid
            );
          }
          return this.loadPreferenceAndPartiesToStore(ucid, defaultParty, userInfo, response, !isPartySearchNotRequired());
        }
      }),
      catchError(error => of(new appActions.PartiesFailed({ failedStatus: error?.status, isCustSummary: false })))
    );
  }

  loadPreferenceAndPartiesToStore(ucid, defaultParty, userInfo, response, isPartySearchRequired) {
    const selectedParty = {
      ucid,
      userRoleID: defaultParty?.roleId,
      userRoleName: defaultParty?.roleName,
      applicationID: CP_APPLICATION_ID,
      partyName: defaultParty?.partyName,
      isPrimary: defaultParty?.isPrimary,
      catRecID: userInfo.catrecid,
      cwsid: userInfo.catloginid
    };

    const preference = this.appUtilService.formPreference(
      response.heliosCustomPreference,
      response.heliosGlobalUserPreference,
      response.partiesAndPermissions
    );

    const primaryPartyData = this.appUtilService.getPrimaryPartyData(response.partiesAndPermissions.parties, selectedParty.ucid);

    return [
      new appActions.LoadSelectedParty(selectedParty),
      new appActions.HeliosPreferencesLoaded(preference),
      new appActions.PartiesLoaded(response.partiesAndPermissions),
      new appActions.ImplicitPartiesLoaded({
        parties: response.implicitParty?.parties?.length ? this.appUtilService.updateImplicitParties(response.implicitParty.parties) : [],
        limit: PARTY_SEARCH_INIT_LIMIT,
        isPartySearchRequired
      }),
      new appActions.LoadPrimaryPartyInfo({ ...primaryPartyData, userType: userInfo.userType })
    ];
  }

  findImplicitParty(partyNumber: string, dealerCode: string): Promise<CustSummaryResponse> {
    return new Promise<CustSummaryResponse>(resolve => {
      this.appService.getImplicitParties({ searchValue: partyNumber, dealerCode, limit: PARTY_SEARCH_INIT_LIMIT }).subscribe({
        next: data => resolve({ ...data, parties: this.appUtilService.updateImplicitParties(data.parties) }),
        error: error => resolve({ parties: [] })
      });
    });
  }

  getHeliosGlobalUserPreference(): Promise<HeliosUserPreferenceResponse> {
    return new Promise<HeliosUserPreferenceResponse>(resolve => {
      this.appService.getHeliosGlobalUserPreference().subscribe({
        next: data => resolve({ userProvided: data.userProvided, defaults: data.defaults, isFailed: false }),
        error: error => resolve({ userProvided: CP_DEFAULT_USER_GLOBAL_PREFERENCE_VALUE, isFailed: true })
      });
    });
  }

  getHeliosCustomPreference(): Promise<HeliosCustomPreference> {
    return new Promise<HeliosCustomPreference>(resolve => {
      this.appService.getHeliosCustomPreference().subscribe({
        next: data => resolve({ ...data, isFailed: false }),
        error: error => resolve({ ...CP_DEFAULT_USER_CUSTOM_PREFERENCE_VALUE, isFailed: true })
      });
    });
  }

  getConsentStatus(): Promise<ConsentInfo> {
    return new Promise<ConsentInfo>(resolve => {
      this.appService.getConsentStatus().subscribe({
        next: data => resolve({ status: data.status, isFailed: false }),
        error: error => resolve({ status: CONSENT_STATUS.DECLINE, isFailed: true })
      });
    });
  }

  /**
   *
   * @returns Promise with parties
   */
  getPartiesAndPermission(): Promise<PartiesDetails> {
    return new Promise<PartiesDetails>((resolve, reject) => {
      this.appService
        .getPartiesAndPermissions()
        .pipe(
          map(data => {
            const parties = this.appUtilService.transformParties(data.body.parties);
            return { parties, userInfo: data.body.userInfo };
          })
        )
        .subscribe({ next: data => this.getCustSummary(data, resolve, reject), error: error => reject(error) });
    });
  }

  /**
   * Invoke CustSummary API if role is super admin/super user else directly return response from Parties and Permissions
   * @param parties - Response from Parties and Permissions
   * @param resolve - Promise resolve callback
   */

  getCustSummary(
    partiesDetails: PartiesDetails,
    resolve: (value: PartiesDetails | PromiseLike<PartiesDetails>) => void,
    reject: (value: HttpErrorResponse | PromiseLike<HttpErrorResponse>) => void
  ) {
    if (this.appUtilService.isSuperAdminOrSuperUser(partiesDetails.parties)) {
      if (APP_ENV === HOSTNAME_TO_ENV_CONFIG.stage || APP_ENV === HOSTNAME_TO_ENV_CONFIG.prod) {
        this.getCustSummaryData(partiesDetails, resolve, reject);
      } else {
        this.mapCustSummaryData(partiesDetails, resolve);
      }
    } else {
      resolve({
        parties: partiesDetails.parties,
        userInfo: partiesDetails.userInfo,
        nextCursor: null,
        isFirst100: true,
        skipModalClose: true
      });
    }
  }

  mapCustSummaryData(partiesDetails: PartiesDetails, resolve: (value: PartiesDetails | PromiseLike<PartiesDetails>) => void): void {
    const custSummary = {
      parties: isDemoModeON() ? MAP_UCID[APP_ENV].parties.map((party) => ({...party, partyName: ''})) : MAP_UCID[APP_ENV].parties,
      userInfo: MAP_UCID[APP_ENV]?.userInfo,
      nextCursor: null,
      isFirst100: true,
      skipModalClose: true
    };
    const custSummaryUpdated = this.updatecustSummaryRole(partiesDetails, custSummary);
    const primaryPartyData = partiesDetails.parties.filter(party => party.isPrimary)[0];
    custSummaryUpdated.primaryPartyInfo = primaryPartyData;
    resolve(custSummaryUpdated);
  }

  getCustSummaryData(
    partiesDetails: PartiesDetails,
    resolve: (value: PartiesDetails | PromiseLike<PartiesDetails>) => void,
    reject: (value: HttpErrorResponse | PromiseLike<HttpErrorResponse>) => void
  ): void {
    this.appService
      .getCustSummary({ limit: PARTY_SEARCH_DEFAULT_LIMIT, cursor: null, searchValue: '' })
      .pipe(
        map(data => {
          const custSummary = {
            parties: data.body.parties,
            nextCursor: data.body.responseMetadata?.nextCursor,
            isFirst100: true,
            skipModalClose: true
          };
          const custSummaryUpdated = this.updatecustSummaryRole(partiesDetails, custSummary);
          const primaryPartyData = partiesDetails.parties.filter(party => party.isPrimary)[0];
          return { ...custSummaryUpdated, userInfo: partiesDetails.userInfo, primaryPartyInfo: primaryPartyData };
        })
      )
      .subscribe(
        custSummary => resolve(custSummary),
        error => reject(error)
      );
  }

  updatecustSummaryRole(partiesDetails: PartiesDetails, custSummary: CustSummary): PartiesDetails {
    const { roleId, roleName } = partiesDetails.parties.filter(party => party.partyType === PARTY.PARTY_TYPE.CAT)[0];
    return this.appUtilService.appendRoot(custSummary, roleId, roleName, '');
  }

  //return promise of cust summary search call response for session & helios party
  getSessionAndHeliosParty(payload): Promise<CustSummaryResponse> {
    return new Promise<CustSummaryResponse>(resolve => {
      this.appService
        .getCustSummary({ ...payload, limit: 100 })
        .pipe(map(data => data.body))
        .subscribe({ next: data => resolve(data), error: error => resolve({ parties: [] }) });
    });
  }
  //returns payload for cust summary search api call
  prepareSearchPartyPayload(partyInfo: Party, searchValue: string) {
    return {
      cursor: null,
      limit: PARTY_SEARCH_INIT_LIMIT,
      roleId: partyInfo.roleId,
      roleName: partyInfo.roleName,
      searchValue
    };
  }
}
