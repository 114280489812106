import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HeliosPreference } from '@Terra/shared/widgets/interface';
import { AppData, getIndustryTypes } from './app.reducer';

// // Lookup the 'App' feature state managed by NgRx
const getAppState = createFeatureSelector<AppData>('app');

export const industryTypesDataSelector = createSelector(getAppState, (state: AppData) => state.industryTypes);
export const getindustryTypesDataSelector = createSelector(industryTypesDataSelector, getIndustryTypes);

export const getindustryTypesDataFailedSelector = createSelector(getAppState, (state: AppData) => state.industryTypesFailed);

export const seenErrorInProductFamilyConfig = createSelector(
  getAppState,
  (state: AppData) => typeof state.productFamilyConfig === 'string'
);

export const getProductFamilyConfigSelector = createSelector(
  getAppState,
  seenErrorInProductFamilyConfig,
  (state: AppData, isError: boolean) => (isError ? {} : state.productFamilyConfig)
);

export const getProductFamilyAvailableSelector = createSelector(
  getAppState,
  seenErrorInProductFamilyConfig,
  ({ productFamilyConfig }, isError: boolean) => !isError && productFamilyConfig && Object.keys(productFamilyConfig).length > 0
);

export const getEnableUpgradeLinkSelector = createSelector(getAppState, ({ enableUpgrade }) => enableUpgrade);

export const getTimezonesListSelector = createSelector(getAppState, (state: AppData) => state.timeZoneList);

export const getBrowserCompatibility = createSelector(getAppState, (state: AppData) => state.isBrowserCompatible);

export const getFeatureList = createSelector(getAppState, (state: AppData) => state.Features);

export const getHeliosUserRoles = createSelector(getAppState, (state: AppData) => state.heliosUserRoles);

export const getUserInfo = createSelector(getAppState, (state: AppData) => state.userInfo);

export const getSelectedParty = createSelector(getAppState, (state: AppData) => state.selectedParty);

export const getPartiesList = createSelector(getAppState, (state: AppData) => state.partiesList);

export const getImplicitPartiesList = createSelector(getAppState, (state: AppData) => state.implicitPartiesList);

export const getImplicitPartiesCount = createSelector(getAppState, (state: AppData) => state.implicitPartiesCount);

export const getPrimaryPartyInfo = createSelector(getAppState, (state: AppData) => state.primaryPartyInfo);

export const getPartiesSearchData = createSelector(getAppState, (state: AppData) => state.partiesSearchData);

export const getPartiesFailed = createSelector(getAppState, (state: AppData) => state.partiesFailed);

export const getImplicitPartiesFailed = createSelector(getAppState, (state: AppData) => state.implicitPartiesFailed);

/** App and Global Preference Selectors */
export const getHeliosPreferencesData = createSelector(getAppState, (state: AppData) => state.heliosPreferences);

export const getAppPreference = createSelector(getHeliosPreferencesData, (preference: HeliosPreference) => preference?.appPreference);
