import { isDemoModeON } from '@Terra/pitmanagement/shared/utils';
import { BROWSER_LANGUAGE_CODES, HeliosCustomPreference } from '@Terra/shared/shared-lib';
import { AppNotification, NotificationType } from '@Terra/shared/widgets/app-notifications';
import { HeliosPreference, HeliosUserPreferenceResponse, MenuItem, Preference } from '@Terra/shared/widgets/interface';
import { dateTimeFormatterHelper, momentDateFormatterHelper, momentHelper, momentTimeFormatterHelper } from '@Terra/shared/widgets/utils';
import { inject, Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DurationInputArg2, unitOfTime } from 'moment-timezone';
import { AppService } from './app.service';
import {
  AFFILIATION_CODES,
  APP_NOTIFICATION,
  APPLICATION_MENULIST,
  DASHBOARD_MENU_PATHS,
  DATE_TIME_CONSTANTS,
  DEFAULT_LANGUAGE,
  DEMO_APPLICATION_MENULIST,
  ERROR_REPORT_TYPE,
  ERROR_TITLE_REPORT_TYPE,
  NOTIFICATION_TYPE,
  PARTY,
  PARTY_FILTER_KEY,
  RETRY_ERROR_TITLE,
  TIME_FORMAT,
  USER_ROLES,
  USER_ROLES_NAME,
  USER_TYPES
} from './appStore.config';
import { LegalUtilService } from './legal-util.service';
import { ConditionalMenuOptConfig, Notification, PartiesDetails, Party, PartyDropdown, PrimaryParty } from './models/app.model';

@Injectable({
  providedIn: 'root'
})
export class AppUtilService {
  public legalUtil: LegalUtilService = inject(LegalUtilService);

  customDownTime = () => [
    this.translocoService.translate(APP_NOTIFICATION.CUSTOM.title),
    this.translocoService.translate(APP_NOTIFICATION.DOWNTIME.title)
  ];

  constructor(private readonly appService: AppService, private readonly translocoService: TranslocoService) {}

  /**
   *
   * @param parties - parties api response
   * @returns whether user is super admin or super super
   */
  isSuperAdminOrSuperUser(parties: Party[]): boolean {
    const superAdminOrUser = [PARTY.ROLE_ID.SUPER_ADMIN, PARTY.ROLE_ID.SUPER_USER];
    return !!parties.find(party => party.partyType === PARTY.PARTY_TYPE.CAT && superAdminOrUser.includes(party.roleId));
  }

  // Appends root to the parties list for super admin on initial load / party search or scroll
  appendRoot(custSummary: PartiesDetails, roleId: string, roleName: string, searchValue: string): PartiesDetails {
    const root: Party = {
      ...PARTY.ROOT,
      roleId,
      roleName
    };
    const isRootPresent = custSummary.parties.find(party => party.partyName === PARTY.ROOT.partyName);
    custSummary.parties = this.mapPartyDetails(custSummary.parties, roleId, roleName);
    if (
      PARTY.ROLE_ID.SUPER_ADMIN === roleId &&
      !isRootPresent &&
      (!searchValue || PARTY.ROOT_SEARCH_VALUES.includes(searchValue.toLowerCase()))
    ) {
      custSummary.parties.unshift(root);
    }
    return custSummary;
  }

  //Maps roleId, roleName & partyType information from terra auth api to each party since cust cummary api doesn't return that info for super admin/user.
  mapPartyDetails(parties: Party[], roleId: string, roleName: string): Party[] {
    return parties.map(party => ({ ...party, partyType: PARTY.PARTY_NUMBER.CAT, roleId, roleName }));
  }

  // mapping roles property with value of the first element of its array since multiple roles may be available for a party but we'll consider only one.
  transformParties(parties: Party[]): Party[] {
    return parties.map(party => {
      const { roles, ...obj } = party;
      return { ...obj, ...roles?.[0] };
    });
  }

  getUserType(catafltncode: string): string {
    if (AFFILIATION_CODES.DEALER.includes(catafltncode)) {
      return USER_TYPES.DEALER;
    } else if (AFFILIATION_CODES.CAT.includes(catafltncode)) {
      return USER_TYPES.CAT;
    }
    return USER_TYPES.CUSTOMER;
  }

  updateImplicitParties(parties: Party[]): Party[] {
    return parties.map(party => ({ ...party, roleId: USER_ROLES.DEALER_STANDARD, roleName: USER_ROLES_NAME[USER_ROLES.DEALER_STANDARD] }));
  }

  //client side search for customer party type
  filterParties(parties: PartyDropdown[], searchText: string): Party[] {
    return parties
      .filter(party => party.data.id.includes(searchText) || party.data.partyName.toLowerCase().includes(searchText.toLowerCase()))
      .map(party => party.data);
  }

  /**
   * Forms preference Data from helios custom & global preference
   * @param heliosCustomPreference
   * @param heliosGlobalUserPreference
   * @param partiesAndPermissions
   */
  formPreference(
    heliosCustomPreference: HeliosCustomPreference,
    heliosGlobalUserPreference: HeliosUserPreferenceResponse,
    partiesAndPermissions: PartiesDetails
  ): HeliosPreference {
    this.legalUtil.rawGlobalPreference = { ...heliosGlobalUserPreference };
    const mapGlobalToAppPreference = this.appService.userPreferenceValueMapping(heliosGlobalUserPreference.userProvided);
    let { ucid, favoriteSites } = heliosCustomPreference;
    ucid = ucid || partiesAndPermissions?.parties[0]?.partyNumber;
    favoriteSites = favoriteSites ? JSON.parse(String(favoriteSites)) : {};
    const appPreference: Preference = {
      ...mapGlobalToAppPreference,
      ucid,
      favoriteSites,
      defaultPreferenceCP: !heliosCustomPreference.ucid
    };
    const globalPreference = {
      ...this.appService.constructHeliosGlobalUserPreference(heliosGlobalUserPreference.userProvided),
      ...{ rawData: heliosGlobalUserPreference }
    };
    return {
      appPreference,
      globalPreference
    };
  }

  /**
   * Forms the menu data based on roleId & feature list of selected party
   * @param userRoleID - user roleId to pass UI
   * @param featureMenuListToRemove - menus to remove that are not present in feature list
   * @returns Filtered menu list
   */
  formMenuList(userRoleID: string, featureMenuListToRemove: string[], conditionalMenuOpt: ConditionalMenuOptConfig[]) {
    const menuData = isDemoModeON() ? DEMO_APPLICATION_MENULIST[userRoleID] : APPLICATION_MENULIST[userRoleID];
    return menuData?.map((menuItem: MenuItem) => {
      let children = menuItem.children?.reduce((filteredChildren, childMenuItem): MenuItem[] => {
        if (!featureMenuListToRemove.includes(childMenuItem.name)) {
          filteredChildren.push({
            ...childMenuItem,
            name: this.translocoService.translate(childMenuItem.name)
          });
        }
        return filteredChildren;
      }, []);
      if (menuItem.name === 'Dashboard') {
        children = children.length > 0 && children.length === 1 ? [] : children;
        menuItem.path = menuItem.pathName = null;
        if (children.length === 0) {
          menuItem.path = DASHBOARD_MENU_PATHS.path;
          menuItem.pathName = DASHBOARD_MENU_PATHS.pathName;
        }
      }
      children = this.addConditionalMenuOption(menuItem, children, conditionalMenuOpt);
      return {
        ...menuItem,
        name: this.translocoService.translate(menuItem.name),
        children
      };
    });
  }

  addConditionalMenuOption(menuItem: MenuItem, children, conditionalMenuOpt: ConditionalMenuOptConfig[]) {
    if (conditionalMenuOpt?.length) {
      conditionalMenuOpt.forEach((menuOpt: ConditionalMenuOptConfig) => {
        if (menuItem.name === menuOpt.parentName && !menuOpt.isParentMenuOpt) {
          menuOpt.menuDetails.name = this.translocoService.translate(menuOpt.menuDetails.name);
          children.push(menuOpt.menuDetails);
        }
      });
    }
    return children;
  }

  getBrowserLanguage(): string {
    const langCodes = Object.keys(BROWSER_LANGUAGE_CODES);
    const browserLang = navigator.language.toLowerCase();
    const hasBrowserLang =
      langCodes.find(lang => lang === browserLang) || langCodes.find(lang => lang.substring(0, 2) === browserLang.substring(0, 2));
    return BROWSER_LANGUAGE_CODES[hasBrowserLang] || DEFAULT_LANGUAGE;
  }

  /** Notification related util services STARTS HERE*/
  prepareFailureNotifications(failedNotifications: AppNotification[], notificationData: Notification): AppNotification[] {
    return failedNotifications.map(notification => ({
      ...notification,
      id: notificationData ? notification.id + 1 : notification.id,
      title: this.translocoService.translate(this.getErrorMessageTitle(notification.payload.reportType), {
        siteName: notification.payload?.siteName
      }),
      message: this.translocoService.translate(this.getErrorMessage(notification.payload.reportType), {
        siteName: notification.payload?.siteName
      }),
      error: this.translocoService.translate(this.getRetryButtonTitle(notification.payload.reportType))
    }));
  }

  /**
   * Method to filter only current active notifications.
   * @param notifications list of API notifications values.
   * @returns active notification or null.
   */
  filterActiveNotifications(notifications: Notification[]): Notification {
    const now = momentHelper.getDateTimeByTZ(undefined, undefined);

    const formatDate = (dateTime: string) => momentHelper.getDateTimeByTZ(dateTime, DATE_TIME_CONSTANTS.DEFAULT_FORMAT);

    const startEndDate = (notification: Notification) => ({
      start: formatDate(notification.notificationStartTime),
      end: formatDate(notification.notificationEndTime)
    });

    const customNotification = this.getCustomNotification(notifications, startEndDate, now);
    const downtimeNotification = this.getDowntimeNotification(notifications, startEndDate, now);

    return customNotification ?? downtimeNotification;
  }

  getCustomNotification(notifications: Notification[], startEndDate, now): Notification {
    const customNotification = notifications.find(nfn => nfn.notificationType === NOTIFICATION_TYPE.Custom);
    if (customNotification) {
      const { start, end } = startEndDate(customNotification);
      if (
        dateTimeFormatterHelper.isBetweenDateTime({
          dateTime: now,
          fromDateTime: start,
          toDateTime: end,
          period: DATE_TIME_CONSTANTS.MINUTES as unitOfTime.StartOf
        })
      ) {
        return customNotification;
      }
    }
    return null;
  }

  getDowntimeNotification(notifications: Notification[], startEndDate, now): Notification {
    const downtimeNotification = notifications.find(nfn => nfn.notificationType === NOTIFICATION_TYPE.Downtime);
    if (downtimeNotification) {
      const { start, end } = startEndDate(downtimeNotification);
      const virtualStart = momentTimeFormatterHelper.getTimeWithSubtract({
        dateTime: start,
        currentFormat: undefined,
        duration: 3,
        period: DATE_TIME_CONSTANTS.DAYS as DurationInputArg2
      });
      if (
        dateTimeFormatterHelper.isBetweenDateTime({
          dateTime: now,
          fromDateTime: virtualStart,
          toDateTime: end,
          period: DATE_TIME_CONSTANTS.MINUTES as unitOfTime.StartOf
        })
      ) {
        return downtimeNotification;
      }
    }
    return null;
  }

  /**
   * Method to prepare notification alert data in AppNotification format.
   * @returns AppNotification with title and content.
   */
  prepareNotificationAlertData(
    preferences: Preference,
    notification: Notification,
    notificationData: AppNotification[]
  ): AppNotification[] {
    const preferredDTF = `${preferences?.dateFormat}; ${
      preferences?.timeFormat === DATE_TIME_CONSTANTS.HOUR_12 ? TIME_FORMAT[12] : TIME_FORMAT[24]
    } z`;
    const dateFormattter = (dateTime: string) =>
      momentDateFormatterHelper.convertDateByTzId(dateTime, momentTimeFormatterHelper.getStandardTimezone(), preferredDTF);

    const startEndDate = (notificationResponse: Notification) => ({
      startDate: dateFormattter(notificationResponse.notificationStartTime),
      endDate: dateFormattter(notificationResponse.notificationEndTime)
    });

    if (preferences && notification) {
      let message = '',
        title = '';
      const index = this.getCustomOrDowntimeNotification(notificationData);
      const id = index === -1 ? notificationData.length + 1 : notificationData[index].id;
      if (notification.notificationType === NOTIFICATION_TYPE.Custom) {
        title = this.translocoService.translate(APP_NOTIFICATION.CUSTOM.title);
        message = notification.notificationMessage;
      } else {
        const { startDate, endDate } = startEndDate(notification);
        title = this.translocoService.translate(APP_NOTIFICATION.DOWNTIME.title);
        message = this.translocoService.translate(APP_NOTIFICATION.DOWNTIME.message, {
          startDateTime: startDate,
          endDateTime: endDate
        });
      }
      return [{ title, message, type: NotificationType.warning, id }];
    }
    return [];
  }

  getErrorMessage(reportType: string): string {
    return reportType ? ERROR_REPORT_TYPE[reportType] : APP_NOTIFICATION.DOWNTIME.message;
  }

  getErrorMessageTitle(reportType: string): string {
    return reportType ? ERROR_TITLE_REPORT_TYPE[reportType] : APP_NOTIFICATION.FILE_EXPORT_ERROR.title;
  }

  getRetryButtonTitle(reportType: string): string {
    return reportType ? RETRY_ERROR_TITLE[reportType] : APP_NOTIFICATION.FILE_EXPORT_ERROR.tryAgain;
  }

  getCustomOrDowntimeNotification(notificationData: AppNotification[]): number {
    return notificationData?.findIndex(notification => notification.type === NotificationType.warning);
  }

  formNotifications(
    preferences: Preference,
    notification: Notification,
    notificationData: AppNotification[],
    failedNotifications: AppNotification[]
  ): AppNotification[] {
    return [
      ...this.prepareNotificationAlertData(preferences, notification, notificationData),
      ...this.prepareFailureNotifications(failedNotifications, notification)
    ].sort((a, b) => b.id - a.id);
  }
  /** Notification related util services ENDS HERE*/

  findPartyDetailsByValue(parties: Party[], key: string, value: string): Party {
    return parties.find(_party => _party[key] === value);
  }

  getPrimaryPartyData(parties: Party[], ucid: string): PrimaryParty {
    let primaryParty = this.findPartyDetailsByValue(parties, PARTY_FILTER_KEY.PARTY_NUMBER, ucid);
    if (!primaryParty) {
      primaryParty = this.findPartyDetailsByValue(parties, PARTY_FILTER_KEY.PARTY_TYPE, USER_TYPES.DEALER);
    }
    return primaryParty;
  }
}
