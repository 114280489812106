import { FeatureList, Preference, SelectedParty, UserInfo } from '@Terra/shared/widgets/interface';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { EnvironmentData, EnvironmentState } from './+environment-state/environment.reducer';
import { AppState } from './+state/app.reducer';
import { getAppPreference, getFeatureList, getUserInfo } from './+state/app.selectors';
import { TimeZoneList } from './models/app.model';

@Injectable({providedIn: 'root'})
export class AppStoreFacadeService {
  private readonly appStore: Store<AppState | EnvironmentState> = inject(Store);
  preferenceData$: Observable<Preference> = this.appStore.select(getAppPreference);
  ucidData$: Observable<SelectedParty> = this.appStore.select((appStore: AppState) => appStore.app.selectedParty);
  environment$: Observable<EnvironmentData> = this.appStore.select((state: EnvironmentState) => state.environment);
  helpUrl$: Observable<string> = this.appStore.select((state: EnvironmentState) => state.environment.helpUrl);
  timeZoneList$: Observable<TimeZoneList[]> = this.appStore.select((states: AppState) => states.app.timeZoneList);
  features$: Observable<FeatureList> = this.appStore.select(getFeatureList);
  userInfo$: Observable<UserInfo> = this.appStore.select(getUserInfo);
}
