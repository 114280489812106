import { HeliosCustomPreference } from '@Terra/shared/shared-lib';
import { HeliosPreference, Preference, SelectedParty, UserInfo } from '@Terra/shared/widgets/interface';
import { Action } from '@ngrx/store';
import { ImplicitPartiesCount, PartiesDetailsPayload, PrimaryParty, TimeZoneList } from '../models/app.model';
export enum AppActionTypes {
  GetPendingUpgrade = '[App] Gets the pending upgrade request for the current user',
  GetPendingUpgradeResult = '[App] Gets the pending upgrade success or failure Result',

  LoadFeatureList = '[App] Get Feature List UCID',
  FeatureListLoaded = '[App] Feature List UCID Loaded',
  ResetFeatureList = '[App] Reset Feature List UCID',

  LoadIndustryTypes = '[App] LoadIndustryTypes',
  IndustryTypesLoaded = '[App] IndustryTypes Loaded',
  IndustryTypeFailed = '[App] IndustryTypeLoad Failed',
  ResetIndustryTypes = '[App] Reset IndustryTypes',

  LoadProductFamilyConfig = '[App] LoadProductFamilyConfig',
  ProductFamilyConfigLoadSuccess = '[App] ProductFamilyConfigLoadSuccess',
  ProductFamilyConfigLoadFailure = '[App] ProductFamilyConfigLoadFailure',

  ResetAppState = '[App] Reset App State',

  LoadTimeZoneList = '[App] Time Zone List',
  LoadTimeZoneListSuccess = '[App] Time Zone List Success',
  LoadTimeZoneListFailure = '[App] Time Zone List Failure',

  UpdateBrowserCompatibility = '[App] Update Browser Compatibility',

  UpdateHeliosCustomPreference = '[App] Update Helios Custom Preference',

  GetPartiesAndPreferences = '[App] Get Parties And Preferences',
  GetPartiesDetails = '[App] Get Parties Details',
  PartiesLoaded = '[App] Parties Loaded',
  PartiesFailed = '[App] Parties Failed',
  SearchPartiesLoaded = '[App] Parties Search Data Loaded',
  ClearSearchParties = '[App] Clear Search Parties',
  GetSessionAndHeliosParty = '[App] Get Session And Helios Party',
  UserInfoLoaded = '[App] UserInfo Loaded',
  LoadSelectedParty = '[App] Load Selected Party',
  HeliosPreferencesLoaded = '[App] Helios Preferences Loaded',
  SetAppPreference = '[App] Set App Preference',
  LoadMenuConfig = '[App] Load Menu Config',
  LoadImplicitParties = '[App] Load Implicit Parties',
  ImplicitPartiesLoaded = '[App] Implicit Parties Loaded',
  ImplicitPartiesFailed = '[App] Implicit Parties Failed',
  LoadImplicitPartiesCount = '[App] Load Implicit Parties Count',
  ImplicitPartiesCountLoaded = '[App] Implicit Parties Count Loaded',
  LoadPrimaryPartyInfo = '[App] Load Primary Party Info',
  FindImplicitPartyDetails = '[App] Find Implicit Party Details'
}

export class GetPendingUpgrade implements Action {
  readonly type = AppActionTypes.GetPendingUpgrade;
  constructor(public payload: string) {}
}

export class GetPendingUpgradeResult implements Action {
  readonly type = AppActionTypes.GetPendingUpgradeResult;
  constructor(public payload: boolean) {}
}

export class LoadFeatureListAction implements Action {
  readonly type = AppActionTypes.LoadFeatureList;
  constructor(public ucid: string, public canRetry: boolean) {}
}

export class FeatureListLoadedAction implements Action {
  readonly type = AppActionTypes.FeatureListLoaded;
  constructor(public payload: any, public canRetry: boolean) {}
}

export class LoadIndustryTypesAction implements Action {
  readonly type = AppActionTypes.LoadIndustryTypes;
  constructor(public payload?: any) {}
}

export class IndustryTypesLoadedAction implements Action {
  readonly type = AppActionTypes.IndustryTypesLoaded;
  constructor(public payload: any) {}
}

export class IndustryTypeFailedAction implements Action {
  readonly type = AppActionTypes.IndustryTypeFailed;
  constructor(public payload: any) {}
}

export class LoadProductFamilyConfigAction implements Action {
  readonly type = AppActionTypes.LoadProductFamilyConfig;
}

export class ProductFamilyConfigLoadSuccessAction implements Action {
  readonly type = AppActionTypes.ProductFamilyConfigLoadSuccess;
  constructor(public payload: any) {}
}

export class ProductFamilyConfigLoadFailureAction implements Action {
  readonly type = AppActionTypes.ProductFamilyConfigLoadFailure;
  constructor(public payload: any) {}
}

export class ResetIndustryTypesAction implements Action {
  readonly type = AppActionTypes.ResetIndustryTypes;
}

export class ResetFeatureListAction implements Action {
  readonly type = AppActionTypes.ResetFeatureList;
}

export class ResetAppStateAction implements Action {
  readonly type = AppActionTypes.ResetAppState;
}

export class LoadTimeZoneListAction implements Action {
  readonly type = AppActionTypes.LoadTimeZoneList;
}
export class LoadTimeZoneListSuccessAction implements Action {
  readonly type = AppActionTypes.LoadTimeZoneListSuccess;
  constructor(public payload: TimeZoneList[]) {}
}
export class LoadTimeZoneListFailureAction implements Action {
  readonly type = AppActionTypes.LoadTimeZoneListFailure;
  constructor(public payload: boolean) {}
}

export class UpdateBrowserCompatibility implements Action {
  readonly type = AppActionTypes.UpdateBrowserCompatibility;
  constructor(public payload: boolean) {}
}

export class UpdateHeliosCustomPreference implements Action {
  readonly type = AppActionTypes.UpdateHeliosCustomPreference;
  constructor(public payload: HeliosCustomPreference) {}
}

export class GetPartiesAndPreferences implements Action {
  readonly type = AppActionTypes.GetPartiesAndPreferences;
  constructor(public payload: any) {}
}
export class GetPartiesDetails implements Action {
  readonly type = AppActionTypes.GetPartiesDetails;
  constructor(public payload: PartiesDetailsPayload) {}
}

export class PartiesLoaded implements Action {
  readonly type = AppActionTypes.PartiesLoaded;
  constructor(public payload: PartiesDetailsPayload) {}
}

export class SearchPartiesLoaded implements Action {
  readonly type = AppActionTypes.SearchPartiesLoaded;
  constructor(public payload: PartiesDetailsPayload) {}
}
export class FindImplicitPartyDetails implements Action {
  readonly type = AppActionTypes.FindImplicitPartyDetails;
  constructor(public payload: any) {}
}
export class ClearSearchParties implements Action {
  readonly type = AppActionTypes.ClearSearchParties;
}

export class GetSessionAndHeliosParty implements Action {
  readonly type = AppActionTypes.GetSessionAndHeliosParty;
  constructor(public payload: any) {}
}

export class UserInfoLoaded implements Action {
  readonly type = AppActionTypes.UserInfoLoaded;
  constructor(public payload: UserInfo) {}
}
export class PartiesFailed implements Action {
  readonly type = AppActionTypes.PartiesFailed;
  constructor(public payload: any) {}
}

export class LoadSelectedParty implements Action {
  readonly type = AppActionTypes.LoadSelectedParty;
  constructor(public payload: SelectedParty) {}
}

export class HeliosPreferencesLoaded implements Action {
  readonly type = AppActionTypes.HeliosPreferencesLoaded;
  constructor(public payload: HeliosPreference) {}
}

export class SetAppPreference implements Action {
  readonly type = AppActionTypes.SetAppPreference;
  constructor(public payload: Preference) {}
}
export class LoadMenuConfig implements Action {
  readonly type = AppActionTypes.LoadMenuConfig;
  constructor(public payload: any) {}
}

export class LoadImplicitParties implements Action {
  readonly type = AppActionTypes.LoadImplicitParties;
  constructor(public payload: any) {}
}

export class ImplicitPartiesLoaded implements Action {
  readonly type = AppActionTypes.ImplicitPartiesLoaded;
  constructor(public payload: any) {}
}

export class ImplicitPartiesFailed implements Action {
  readonly type = AppActionTypes.ImplicitPartiesFailed;
  constructor(public payload: any) {}
}

export class LoadImplicitPartiesCount implements Action {
  readonly type = AppActionTypes.LoadImplicitPartiesCount;
  constructor(public payload: any) {}
}

export class ImplicitPartiesCountLoaded implements Action {
  readonly type = AppActionTypes.ImplicitPartiesCountLoaded;
  constructor(public payload: ImplicitPartiesCount) {}
}

export class LoadPrimaryPartyInfo implements Action {
  readonly type = AppActionTypes.LoadPrimaryPartyInfo;
  constructor(public payload: PrimaryParty) {}
}

export type AppActions =
  | LoadFeatureListAction
  | FeatureListLoadedAction
  | LoadIndustryTypesAction
  | IndustryTypesLoadedAction
  | IndustryTypeFailedAction
  | ResetIndustryTypesAction
  | ResetAppStateAction
  | LoadProductFamilyConfigAction
  | ProductFamilyConfigLoadSuccessAction
  | ProductFamilyConfigLoadFailureAction
  | ResetFeatureListAction
  | LoadTimeZoneListAction
  | LoadTimeZoneListSuccessAction
  | LoadTimeZoneListFailureAction
  | UpdateBrowserCompatibility
  | GetPendingUpgradeResult
  | UpdateHeliosCustomPreference
  | GetPartiesAndPreferences
  | GetPartiesDetails
  | PartiesLoaded
  | PartiesFailed
  | SearchPartiesLoaded
  | ClearSearchParties
  | GetSessionAndHeliosParty
  | UserInfoLoaded
  | LoadSelectedParty
  | HeliosPreferencesLoaded
  | SetAppPreference
  | LoadMenuConfig
  | LoadImplicitParties
  | ImplicitPartiesLoaded
  | ImplicitPartiesFailed
  | LoadImplicitPartiesCount
  | ImplicitPartiesCountLoaded
  | FindImplicitPartyDetails
  | LoadPrimaryPartyInfo;
