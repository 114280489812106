import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppEffects } from './+state/app.effects';
import { appReducer } from './+state/app.reducer';
import { AppService } from './app.service';

@NgModule({
  imports: [CommonModule, HttpClientModule, StoreModule.forFeature('app', appReducer), EffectsModule.forFeature([AppEffects])],
  providers: [AppService]
})
export class AppStoreModule {}
