import { Action } from '@ngrx/store';

export enum EnvironmentActionTypes {
  LoadEnvironment = '[Environment] Load Data',
  EnvironmentLoaded = '[Environment] Data Loaded',
  IdentifyEnvironment = '[Environment] identifyEnv'
}

export class LoadEnvironment implements Action {
  readonly type = EnvironmentActionTypes.LoadEnvironment;
  constructor(public payload: any) {}
}

export class EnvironmentLoaded implements Action {
  readonly type = EnvironmentActionTypes.EnvironmentLoaded;
  constructor(public payload: any) {}
}

export class IdentifyEnvironment implements Action {
  readonly type = EnvironmentActionTypes.IdentifyEnvironment;
  constructor(public env: string, public baseURL: string) {}
}

export type EnvironmentActions = LoadEnvironment | EnvironmentLoaded | IdentifyEnvironment;
